import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Switch from "../../../components/switch";
import Loading from "../../../components/loading";
import { CreateMldcAcc, getParent, pullChild, syncChild, changeParentField } from "../../../store/actions/parent";
import { parentLoadingSelector } from "../../../store/parent";
import ParentDataField from "./parentDataField";

const ParentCard = () => {
    const dispatch = useDispatch();
    const { parentId } = useParams();
    const parentData = useSelector(state => state.parent);
    const isLoading = useSelector(parentLoadingSelector);

    const mldcCreateHandler = () => {
        dispatch(CreateMldcAcc(parentId));
    }

    const deleteChild = (childId) => {
        dispatch(pullChild(parentId, childId))
    }

    const syncChildsHandler = () => {
       dispatch(syncChild(parentId))
    }

    const changeFieldHandler = ({target}) => {
        dispatch(changeParentField (parentId, {[target.name]: target.checked}))
     }

    useEffect(()=>{
        dispatch(getParent(parentId));
    },[dispatch, parentId])

    if(isLoading) {
        return null
    }

    return(
        <div>
            { parentData.isLoading && <Loading /> }
            <div className="field-line">
                <b className='paybycard__title'>Имя: </b> 
                <ParentDataField field={"name"} parentId={parentId} startValue={parentData.name} />
            </div>
            <div className="field-line">
                <b className='paybycard__title'>email: </b>
                <ParentDataField field={"email"} parentId={parentId} startValue={parentData.email} />
            </div>
            {!parentData.isLoading && 
            <div>
                idMldc: { parentData.mldConnectId ?
                parentData.mldConnectId
                :
                <button onClick={mldcCreateHandler}>Создать "Молодец!"</button>
                }
            </div>}
            <div className="field-line">
                <b className="paybycard__title">
                    Оплата картой: 
                </b> 
                <div className="paybycard__status">
                     <Switch 
                        value={parentData.canPayByCard} 
                        onChageFn={changeFieldHandler} 
                        name={"canPayByCard"} />
                </div>
            </div>
            <div className="field-line">
                <b className="paybycard__title">
                    Показать баланс: 
                </b> 
                <div className="paybycard__status" >
                    <Switch 
                        value={parentData.showBalance} 
                        onChageFn={changeFieldHandler} 
                        name={"showBalance"} />
                </div>
            </div>
            <div>
                <h3>Дети</h3> 
                {parentData.childs.map(el=>{
                    return <div key={"child_"+el._id}><button onClick={()=>{deleteChild(el._id)}}>x</button> <Link to={"/archive/"+el._id}>{el.name}</Link></div>
                })}
                <button onClick={syncChildsHandler}>Синхронизировать</button>
            </div>
        </div>
    )
}

export default ParentCard;