import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { ClearMessage } from "../store/actions/toast";

const Toaster = () => {

    const message = useSelector(state => state.toast.message);
    const messageStyle = useSelector(state => state.toast.emotional);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(message?.length > 0){
            setTimeout(()=>{
                dispatch(ClearMessage())
            }, 3000)
        }
    },[message, dispatch])

    return(
        <>
        {message.length > 0 &&
            <div className={"toast-wrap toast-wrap--"+messageStyle}>
                {message}
            </div>
        }
        </> 
    )
}

export default Toaster;