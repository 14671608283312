import { useEffect, useState } from "react"
import $api from "../service/api-service"

const ActualGroupCourse = ({students, groupId}) => {

    return (
        <div className="actual-courses__list">
            {students.map(el=>{
                return(
                    <ActualGroupCourseItem groupId={groupId} key={'actual'+el._id} student={el}/>
                )
            })}
        </div>
    )
}

const ActualGroupCourseItem = ({student, groupId}) => {
    const [courses, setCourses] = useState([]);
    const [selectValue, setSelectValue] = useState({
        courseName: "", _id: ""
    });
    const [showCorrecting, setShowCorrecting] = useState(false);

    useEffect(()=>{
        let isCancelled = false

        $api.get('/progress/student-courses/'+student._id)
        .then(({data})=>{
            if(!isCancelled) {
                setCourses(data.reverse());
                setSelectValue(data.find(el=>el.groupID === groupId && el.isActive));
            }
        })

        return () => {isCancelled = true}
    },[groupId, student._id])

    const selectChangeHandler = ({target}) => {
        const {value} = target
        $api.post('/progress/set-actual',
            {
                groupID: groupId,
                newActualId: target.value,
                prevActualId: selectValue
            }
        ).then(()=>{
            setSelectValue(p=>{return {...p, _id: value}})});
    }
    

    const saveBtnHandler = () => {
        setShowCorrecting(p=>!p);
        $api.patch('/progress/change-sc-name/'+selectValue._id, {courseName: selectValue.courseName})
        .then(data=>{
            setCourses( p=>p.map(
                el=> {
                    if(el._id !== selectValue._id) {
                        return el
                    }
                    return {...el, courseName: selectValue.courseName}
                }))
            })
    }

    return (
        <div className="actual-student-course__wrap">
            {
                selectValue && 
                <>
                    <select className="actual-student-course__select" onChange={selectChangeHandler} value={selectValue?._id} key={'actual_'+student._id}>
                        {courses.map(el=>{
                            return <option key={'select_'+el._id} value={el._id}>{el.courseName} ({el.autoHours+ el.manualHours})</option>
                        })}
                    </select>
                    { !showCorrecting ?
                    <button onClick={()=>setShowCorrecting(p=>!p)}>ред</button>
                    :
                    <form onSubmit={saveBtnHandler}>
                        <input onChange={({target})=>setSelectValue(p=>{return {...p, courseName: target.value}})} value={selectValue.courseName} />
                        <button type="submit">сохр</button>
                    </form>
                    }
                </>
            }
        </div>
    )
}

export default ActualGroupCourse;