const defaultState = {
    _id: "",
    isActive: true,
    lessons: [],
    notes:"",
    name: "",
    progress: [],
    students: [],
    teacher: {},
    oneTimers: [],
    studentProgress: [],
    isLoading: false,
    lessonsLength: "",
    schedule: {
        days: []
    },
    specialSalary: ""
}

export const groupInfoReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_INFO_LESSONS":
            return {...state, _id:action.payload._id, ...action.payload}
        
        case "SET_NOTES":
            return {...state, notes: action.payload}

        case "SET_GROUP_LOADING":
            return {...state, isLoading: action.payload}
        
        case "SET_GROUP_ONETIMERS":
            return {...state, oneTimers: action.payload}

        case "SET_GROUP_SCHEDULE":
            return {...state, schedule: action.payload}
        
        case "UPDATE_LESSON_IN_LIST":
            return {...state, lessons: state.lessons.map(el=>{
                if(el._id !== action.payload._id) {
                    return el;
                }
                return action.payload
            })}
        
        default:
            return state
    }
}

export const scheduleSelector = (state) =>  state.groupInfo.schedule;
export const specialSalarySelector = (state) =>  state.groupInfo.specialSalary;
