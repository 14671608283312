import React, { useCallback, useEffect, useState } from "react";
import useFetch from '../hooks/useFetch.js';
import TeacherSelect from "./teacherSelect.js";

const TeacherChange = ({updateFunction, groupID, groupTeacher}) => {
    const [teacherID, setTeacherID] = useState('');
    const [{response}, doFetch] = useFetch('/group/teacher/change/'+teacherID);
    const [changeState, setChangeState] = useState(false)

    const ChangeTeaherHandler = ()=>{
        setChangeState(c=>!c);
    }

    const changeTeacher = useCallback(({target}) => {
        setTeacherID(target.value)
        doFetch({
            method: 'put',
            data: {
                groupID: groupID
            }
        })

    },[doFetch, groupID])

    const teacherClickHandler = () => {
        setChangeState(true)
    }

    useEffect(()=>{
        if(!response) {
            return;
        }
        ChangeTeaherHandler();
        updateFunction();
    },[ response, updateFunction])

    return (
        <div className="teacher-changer__wrap">
            {changeState ? 
            <div className="techer__changer-line">
                <TeacherSelect defaultTeacherID={groupTeacher._id} changeHandler={changeTeacher}/>
                <button className="teacher-change__btn teacher-change__btn--delete" onClick={ChangeTeaherHandler}>x</button>
            </div> :
            <p onClick={teacherClickHandler} className="group-teacher__name">{groupTeacher?.userName}</p>}
        </div>
    )
}

export default TeacherChange;