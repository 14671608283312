import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFetch from '../hooks/useFetch.js'
import FilialCreation from './filialSelect.js';
import TeacherSelect from './teacherSelect.js';
import AddToGroup from './addToGroupComponent.js';
import { Link } from 'react-router-dom';

const GroupCreation = () => {
    const [groupDate, setGroupData] = useState({
        name: "", teacherId: "", progressName: "", recomendPrice: 1000, lessonsLength: 1
    });

    const [{response, error}, doFetch] = useFetch('/group/create');
    const userRole = useSelector(state => state?.currentUser?.currentUser?.userRole);

    const [message, setMessage] = useState("")


    const changeHandler = ({target}) => {
        setGroupData({...groupDate, [target.name]: target.value});
    }

    const createHandler = (evt) => {
        evt.preventDefault();
        if(groupDate.name.length > 0 && groupDate.teacherId) {
            if(!groupDate.filial) {
                groupDate.filial = userRole?.filialAccess[0]._id;
            }
            doFetch({
                method: 'post',
                data: {...groupDate}
            })
            setGroupData({...groupDate, name: "", progressName: ""})
        } else {
            setMessage(
                <p>
                    Даша, нельзя создавать группы с пустым именем или без препода!
                </p>
            )
        }
    }

    useEffect(()=>{
        if(!error){
            return;
        }

        setMessage(<div>{error.response.data?.message}</div>);
    }, [error])

    useEffect(()=>{
        if(!response){
            return
        }
        
        setMessage(
            <>
                <div>Группа <b><Link to={'/hour-info?'+response._id}>{response.name}</Link></b> создана</div>
                <div>ID группы: <b>{response._id}</b></div>
                <AddToGroup groupID={response._id} updateFunc={()=>{}}/>
            </>
        )
    },[response])

    return(
        <div className="group-creation">
            <div>
                <h2>Группы</h2>
                <form onSubmit={createHandler}>
                    <div className="creating-page__input-wrap"> 
                        <FilialCreation changeHandler={changeHandler} filialAccess={userRole?.filialAccess}/>
                    </div>
                    <div className="creating-page__input-wrap"> 
                        <input required autoComplete='off' type="text" placeholder="Название группы" value={groupDate.name} name="name" onChange={changeHandler} />
                    </div>
                    <div className="creating-page__input-wrap"> 
                        <input required autoComplete='off' type="text" placeholder="Название курса" value={groupDate.progressName} name="progressName" onChange={changeHandler} />
                    </div>
                    <div className="creating-page__input-wrap recomend-price"> 
                        <label className='' htmlFor='recomendPrice'>
                            Сумма за час 
                        </label>
                            <input id='recomendPrice' required autoComplete='off' type="number" placeholder="Рекомендованная сумма за час" value={groupDate.recomendPrice} name="recomendPrice" onChange={changeHandler} />
                    </div>
                    <div className="creating-page__input-wrap recomend-price"> 
                        <label className='' htmlFor='lessonsLength'>
                            Длительность урока 
                        </label>
                            <input id='lessonsLength' required autoComplete='off' type="number" step='0.25' placeholder="Рекомендованная сумма за час" value={groupDate.lessonsLength} name="lessonsLength" onChange={changeHandler} />
                    </div>
                    <div className="creating-page__input-wrap" >
                        <TeacherSelect changeHandler={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <button type='submit' className="yc_button">Создать</button>
                    </div>
                </form>
            </div>
            <div className="group-creation__log">
                <h3>Логи</h3>
                <div>
                    {message}
                </div>
            </div>
        </div>
    )
}

export default GroupCreation