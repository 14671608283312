import {useEffect} from 'react'
import useFetch from '../hooks/useFetch.js'
import { useDispatch, useSelector } from 'react-redux'

const CurrentUserChecker = ({children}) => {
  const dispatch = useDispatch();
  const [{response}, doFetch] = useFetch('/auth/refresh');
  const token = localStorage.getItem('token');
  const loading = useSelector(state => state.currentUser.isLoading)
  useEffect(() => {
    if (!token) {
      dispatch({type: 'SET_UNAUTHORIZED'})
      return
    }

    doFetch()
    dispatch({type: 'LOADING'})
  }, [doFetch, dispatch, token])

  useEffect(() => {
    if (!response) {
      return
    }

    dispatch({type: 'SET_AUTHORIZED', payload: response.user})
  }, [response, dispatch])
  
  if(loading) {
    return null
  }
  return children
}

export default CurrentUserChecker
