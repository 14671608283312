import $api from "../../service/api-service";
import { ShowMessage } from "./toast";

export const getParent = ( parentId ) => {
    return dispatch => {
        dispatch({type: "SET_PARENT_LOADING", payload: true});
        $api.get(`/parent/${parentId}`)
            .then(response => {
                if(response.status === 200) {
                    dispatch({type: "SET_PARENT_DATA", payload: response.data});
                }
            })
            .catch(err=>{
                dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_PARENT_LOADING", payload: false});
            })
    }
}

export const CreateMldcAcc = ( parentId ) => {
    return dispatch => {
        dispatch({type: "SET_PARENT_LOADING", payload: true});
        $api.patch('/parent/create-mldc/'+parentId)
            .then(response=>{
                if(response.status === 200) {
                    dispatch(ShowMessage("Аккаунт успешно создан", "success"));
                    dispatch({type: "SET_PARENT_DATA", payload: response.data});
                }
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_PARENT_LOADING", payload: false});
            })
    }
}

export const changeParentField = ( parentId, params ) => {
    return dispatch => {
        dispatch({type: "SET_PARENT_LOADING", payload: false});
        $api.patch(`/parent/${parentId}`, {params:params})
        .then(response => {
                if(response.status === 203) {
                    dispatch({type: "SET_PARENT_DATA", payload: response.data});
                }
            })
            .catch(err=>{
                dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_PARENT_LOADING", payload: false});
            })
    }
}

export const pullChild = (parentId, childId) => {
    return dispatch => {
        dispatch({type: "SET_PARENT_LOADING", payload: false});
        $api.patch(`/parent/deletechild/${parentId}`, {child: childId})
            .then(response => {
                if(response.status === 200) {
                    dispatch({type: "SET_PARENT_DATA", payload: response.data});
                }
            })
            .catch(err=>{
                console.log("Eroror", err)
                dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                
                dispatch({type: "SET_PARENT_LOADING", payload: false});
            })
    }
}

export const syncChild = (parentId) => {
    return dispatch => {
        dispatch({type: "SET_PARENT_LOADING", payload: false});
        $api.patch(`/parent/sync-childs/${parentId}`)
            .then(response => {
                if(response.status === 200) {
                    dispatch(ShowMessage(response.data.message, "success"));
                }
            })
            .catch(err=>{
                console.log("Eroror", err)
                dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                
                dispatch({type: "SET_PARENT_LOADING", payload: false});
            })
    }
}