import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChangePaymentCompany, CreateParent, DeleteStudent, GetStudentData, SetActiveStudent } from '../../../store/actions/students.js';
import Loading from '../../../components/loading.js';
import Switch from "../../../components/switch";

import StudentDataField from './studentDataField.js';
import { NavLink, useParams } from 'react-router-dom';
import { lastNotificationSelector, notificationLoadingSelector } from '../../../store/paymentNotification.js';
import { beautifulDate } from '../../../service/dateConverter.js';
import { sendNotification } from '../../../store/actions/payNotication.js';
import { translateDayOfWeek } from '../../../utils/dayOfWeekUtils.js';
import PaymentLinkGenerator from '../../../components/paymentLinkGenerator.js';



const canNewNotify = (lastNotify) => {
    if(!lastNotify) return true;
    const today = new Date(lastNotify);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return new Date() > tomorrow
}

const StudentCard = (studentState) => {
    const dispatch = useDispatch();
    const { userId } = useParams();
    const { notificationDate } = useSelector(lastNotificationSelector);
    const notificationLoading = useSelector(notificationLoadingSelector);
    const canSendNewNotify = canNewNotify(notificationDate) && studentState.parent

    useEffect(()=>{
        dispatch(GetStudentData(userId));
    },[dispatch, userId])


    const createParentHandler = () => {
        dispatch(CreateParent(userId));
    }

    const deleteStudentHandler = () => {
        dispatch(DeleteStudent(userId))
    }


    const notifyHandler = () => {
        dispatch(sendNotification(userId))
    }


    const changeFieldHandler = ({target}) => {
        dispatch(ChangePaymentCompany(userId, target.checked))
     }

    
    if(!userId) {
        return null;
    }

    
    return (
       <>
        <i className='student-card__filials'>{studentState.filials.map(el=>el.name+" ")}</i>
        <div className='field-line'>
            <NavLink to={"/archive/balance/"+userId}>
                {studentState.balanceSum+""}
            </NavLink>
            <div>
                {!notificationLoading && canSendNewNotify && <button onClick={notifyHandler} className='student-card__notify-btn'>Напомнить</button>}
            </div>
            <span className='last-notify__date'>{beautifulDate(notificationDate)}</span>
        </div>

        <div className="field-line">
            <b className='field-title'>Имя: </b>
            <StudentDataField 
                studentID={userId} 
                field={"name"} 
                startValue={studentState.name || ""} 
                />
        </div>
        { !studentState.parent && 
            <div className="field-line">
                <b className='field-title'>Email: </b>
                <StudentDataField 
                    studentID={userId} 
                    field={"email"} 
                    startValue={studentState.email || ""} 
                    />
            </div>
        }
        <div className="field-line">
            <b className='field-title'>Номер договора: </b>
            <StudentDataField 
                studentID={userId} 
                field={"contract"} 
                startValue={studentState.contract || ""} 
                />
        </div>
        <div className="field-line">
            <b className='field-title'>Ник в дискорд: </b>
            <StudentDataField 
                studentID={userId} 
                field={"discordName"} 
                startValue={studentState.discordName || ""}
                />
        </div>
        <div className="field-line">
            <b className='field-title'>Реком. сумма: </b>
            <StudentDataField 
                studentID={userId} 
                field={"recomendSum"} 
                startValue={studentState.recomendSum || ""} 
                />
        </div>
        { !studentState.parent &&
            <div className="field-line">
                <b className='field-title'>Имя родителя: </b>
                <StudentDataField 
                    studentID={userId} 
                    field={"parentName"} 
                    startValue={studentState.parentName || ""} 
                    />
            </div>
        }
        {studentState.parent.mldConnectId &&
            <div className="field-line" >
                <b className='field-title'>Новый счет: </b>
                <Switch 
                    value={studentState.payToYesProg} 
                    onChageFn={changeFieldHandler} 
                    name={"showBalance"} />
            </div>
        }
        <div className="field-line">
            <b className="field-title">Активный: </b>
            <input type="checkbox" onChange={ evt => dispatch(SetActiveStudent(evt.target, userId)) } checked={studentState.isActive}/>
        </div>

        <div>
            <PaymentLinkGenerator contract={studentState.contract} email={studentState.parent?.email} />
            <a className='student-card__payment-link' href={studentState.paymentLink}>{studentState.paymentLink}</a>
        </div>

        <div className="field-line">
            {!!studentState.parent ? 
                <div className='parent__in-student'>
                    <NavLink to={'/archive/parent/'+studentState.parent._id}>Родитель создан</NavLink> 
                    { studentState.parent.mldConnectId && <div className='parent__active-cab'></div> }
                </div>
                : 
                <button onClick={createParentHandler}>Создать родителя</button>}
        </div>
        <div>
            <button onClick={deleteStudentHandler} className='lesson-item__remove-btn'>
                Удалить студента
            </button>
        </div>
        
        <div className='student-card__price-wrap'>
            <h4>Группы</h4>
            {studentState.groups?.map(el=>{
                return(
                    <table className={el.isActive ? "" : 'student-card__archive-group'} key={el._id}>
                    <thead>
                        <tr>
                            <td colSpan="2" >  
                            <NavLink to={"/hour-info/?"+el._id}>{el.name}</NavLink>
                            </td>
                        </tr>
                        {el.groupSchedule?.days?.map(el=>(
                            <tr key={el._id}>
                                <td>
                                    {translateDayOfWeek(el.dayOfWeek)}
                                </td>
                                <td>
                                    {el.time.startTime} – {el.time.endTime}
                                </td>
                            </tr>
                            )
                        )}
                    </thead>
                    </table>
                )
            })}
        </div>

        {studentState.isLoading && <Loading/>}
       </>
    )
}

export default StudentCard;