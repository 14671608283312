import { useCallback, useEffect, useState } from "react";
import $api from "../../../service/api-service";
import createCurMount from "../../../service/monthCreator";
import { NavLink } from "react-router-dom";

const TeacherHourInFilial = ({ filial }) => {
    const [teachersData, setTeacherData] = useState([]);
    const [month, setMonth] = useState(createCurMount());

    const monthChangeHandler = (evt) => {
        setMonth(evt.target.value);
    }
    
    const fetchLessons = useCallback((evt) => {
        $api.get(`/lesson/count-all-lessons/${month}/2024/`+filial._id)
            .then(response => setTeacherData(response.data));
    },[filial._id, month])

    useEffect(()=>{
        let isCancelled = false;
        if(!filial) {
            return
        }
        if(!isCancelled) fetchLessons();

        return () => {
            isCancelled = true;
        };
       
    },[filial, fetchLessons]);
    return(
        <div>
            <select className="lesson-mgmnt__mounth" onChange={monthChangeHandler} value={month}>
                <option value="01">Январь</option>
                <option value="02">Февраль</option>
                <option value="03">Март</option>
                <option value="04">Апрель</option>
                <option value="05">Май</option>
                <option value="06">Июнь</option>
                <option value="07">Июль</option>
                <option value="08">Август</option>
                <option value="09">Сентябрь</option>
                <option value="10">Октябрь</option>
                <option value="11">Ноябрь</option>
                <option value="12">Декабрь</option>
            </select>
            <table>
                <caption>{filial.name}</caption>
                <thead>
                    <tr>
                        <th>Преподаватель</th>
                        <th>Групп</th>
                        <th>Индив</th>
                        <th>Специальные</th>
                        <th>Пробные</th>
                        <th>Общее</th>
                    </tr>
                </thead>
                <tbody>
                    {teachersData.map(el=>{
                        return (
                            <tr key={el?.userName+filial._id}>
                                <td className="th-table__name">
                                    <NavLink to={`/teachers-hours/${el.userId}/${month}`}>{el?.userName}</NavLink>
                                </td>
                                <td>
                                    {el?.group?.toString().replace('.', ',')}
                                </td>
                                <td>
                                    {el?.indiv?.toString().replace('.', ',')}
                                </td>
                                <td>
                                    {el?.specialSalary?.toString().replace('.', ',')}
                                </td>
                                <td>
                                    {el?.test?.toString().replace('.', ',')}
                                </td>
                                <td>
                                    {el?.hours?.toString().replace('.', ',')}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default TeacherHourInFilial;