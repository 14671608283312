import $api from '../service/api-service';

let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
let isLoggedIn = localStorage.getItem('user') ? true : false
const fullUserInfo = {
  telegramLink: "",
  discordName: "",
  phoneNumber: "",
  isReadyToOffline: "",
  district: "",
  vkLink: "",
  patronymic: "",
  BIC: "",
  bankNum: "",
  INN: "",
}

const initialState = {
  isLoading: false,
  isLoggedIn: isLoggedIn,
  currentUser: user,
  fullUserInfo: fullUserInfo
}

export const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return {...state, isLoading: true}
    case 'SET_AUTHORIZED':
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        currentUser: action.payload
      }
    case 'SET_UNAUTHORIZED':
      $api.post(`/auth/logout`, {withCredentials: true})
      localStorage.clear();
      return {
        ...state,
        isLoggedIn: false,
        currentUser: null
      }
    case 'SET_USER':
      return {
        ...state,
        fullUserInfo: {
          ...state.fullUserInfo,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export const filialsSelect = state => state.currentUser.currentUser.userRole.filialAccess;
export const isAdminSelector = state => state.currentUser.currentUser.isAdmin;