import { useState } from "react";
import { useDispatch } from "react-redux";
import $api from "../../../service/api-service";
import { ShowMessage } from "../../../store/actions/toast";

const ReviewPageComment = ({ comment, reviewId }) => {
    const [isChange, setIsChange] = useState(false);
    const [commentValue, setCommentValue] = useState(comment);
    const dispatch = useDispatch();

    const changeCommentHandler = ({target}) => {
        setCommentValue(target.value);
    }

    const commentChangeHandler = () => {
        setIsChange(p=>!p)
    }

    const submitHandler = (evt) => {
        evt.preventDefault();
        $api.put('/review/comment/'+reviewId, {comment: commentValue})
            .then(response => {
                dispatch(ShowMessage(response.data.message, "success"));
                setIsChange(false);
            })
    }

    return(
        <div className="review-page__comment-wrap">
        {isChange ? 
            <form onSubmit={submitHandler}>
                <textarea rows={12} cols={30} value={commentValue} onChange={changeCommentHandler}>
                </textarea>
                <input type="submit" value={"Сохранить"} />
            </form>
            :
            <div onClick={commentChangeHandler} className="review__comment">
                    {commentValue}</div>
        }
        </div>
    )
}

export default ReviewPageComment;