import { useState } from "react";
import $api from "../../../service/api-service";
import { useSelector } from "react-redux";
import { specialSalarySelector } from "../../../store/groupInfo";

const GroupSpecialSalary = ({groupId}) => {
    const specialSalary = useSelector(specialSalarySelector);
    const [checkboxValue, setCheckboxValue] = useState(specialSalary === 'OnOff');
    const checkboxChangeHandler = ({target}) => {
        setCheckboxValue(target.checked);
        $api.patch('/group/change/salary-special-type/'+groupId, 
            {type: target.checked ? 'OnOff' : 'null' })
    }

    return(
        <div className="group__special-type">
            <span>
            Особая:
                </span> <input type="checkbox" onChange={checkboxChangeHandler} checked={checkboxValue}/>
        </div>
    )
}

export default GroupSpecialSalary;