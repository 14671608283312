import React, { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch.js';

const TeacherCreation = () => {

    const [{response}, doFetch] = useFetch('/auth/register');
    const [message, setMessage] = useState('')
    const [formData, setFromData] = useState({
        email: "", userName: "", password: ""
    })

    const creationHandler = () => {
        doFetch({
            method: 'post',
            data: {
                email: formData.email,
                userName: formData.userName,
                password: formData.password
            }
        })
    }

    const changeHandler = ({target}) => {
        setFromData({...formData, [target.name]: target.value})
    }

    useEffect(() => {
        if(!response) {
            return;
        }
        setMessage(
            <div>
                <p><b>Логин: </b><span>{response.email}</span></p>
                <p><b>Пароль: </b><span>{formData.password}</span></p>
            </div>
        )
       
    },
    [response, formData.password]);

    const submitHandler = (evt) => {
        evt.preventDefault();
    }

    return(
        <div className="group-creation">
            <div>
                <h2>Создание преподавателя</h2>
                <form autoComplete='off' onSubmit={submitHandler}>
                    <div className="creating-page__input-wrap">
                        <input id='' type="text" name="userName" placeholder="Имя преподавателя" onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <input type="email" name="email" onChange={changeHandler} placeholder="email"/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <input name="password" onChange={changeHandler} type="password" placeholder="пароль"/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <button className="yc_button" onClick={creationHandler} >Создать</button>
                    </div> 
                </form>
            </div>
            <div className="group-creation__log student-creating">
                <h3>Логи</h3>
                <div>
                    {message}
                </div>
            </div>
        </div>
    )
}

export default TeacherCreation;