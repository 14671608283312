import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginUser } from '../store/actions/auth';

const AuthPage = () => {
    const [user, setUser] = useState({email:"", password:""});
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser);

    const changeHandler = useCallback(({target}) => {
        setUser({...user, [target.name]: target.value});
    },[setUser, user])

    const loginHandler = useCallback(() => {
        dispatch(loginUser(user.email, user.password));
    },[dispatch, user])

    const enterHandler = useCallback((e => {
        if(e.key === "Enter"){
            loginHandler();
    }}),[loginHandler]) 

    if (currentUser.isLoggedIn) {
        return <Redirect to="/" />
    }

    return(
        <div>
            <div className="container" onKeyUp={enterHandler} key="Enter">
                <div className="auth-page__wrap">
                    <div className="main-page-headline__wrap main-page__headline--auth">
                        <h1 className="main-page__headline ">Страница Авторизации</h1>
                    </div>
                    <div className="main-page-description__wrap">
                        <p className="main-page__description">Логин и пароль от личного кабинета тебе предоставит твой тим лид.<br /> Если ты его еще не получил напомни ему об этом, он обязательно поможет тебе</p>
                    </div>
                    <div className="auth-page__form">
                        <input type="email" placeholder="email" name="email" onChange={changeHandler}/>
                        <input type="password" placeholder="password" name="password" onChange={changeHandler}/>
                        <button type="submit" onClick={loginHandler} key="Enter">Вход</button>
                    </div>
                </div>
            </div>
            <div className="main-page__img-wrap">
                {/* <img alt="шагающие люди" src="./img/mainpageImage.png"/> */}
            </div>
        </div>
    )
}

export default AuthPage;
