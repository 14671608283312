import { useEffect, useState } from "react";
import { beautifulDate } from "../service/dateConverter";
import useFetch from '../hooks/useFetch';
import { Link } from "react-router-dom";

const IncomesPage = () => {
    const [incomes, setIncomes] = useState([]);
    const [{response}, doFetch] = useFetch('/income/all');

    useEffect(()=>{
        doFetch()
    },[doFetch])

    useEffect(()=>{
        if(!response) {
            return
        }
        setIncomes(response)
    },[response])

    return (
        <main className="container">
            <h1>Оплаты</h1>
            <table>
            <tbody>            
                {incomes.map(el=>{
                    return (
                        <tr key={el._id}>
                            <td>
                                <Link to={"/archive/"+ el.balance.student._id} >
                                    {el.balance.student.name}
                                </Link>
                            </td>
                            <td>
                                {el.amount} ₽ 
                            </td>
                            <td>
                                {beautifulDate(el.date)}
                            </td>
                            <td>
                                {(el.info)}
                            </td>
                            <td>
                                {(el.balance.student.filials.map(filial=>filial.name+" "))}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </main>
    )
}

export default IncomesPage;