import { useState } from "react";
import { useDispatch } from "react-redux";
import { changeParentField } from "../../../store/actions/parent";

const ParentDataField = ({ parentId ,field, startValue="" } )=> {
    const [inputValue, setInputValue] = useState(startValue);
    const [changeValue, setChangeValue] = useState(false);
    const dispatch = useDispatch();

    const formSubmitHandler = (evt) =>{
        evt.preventDefault();
        const data = { 
                [field]: inputValue,
            }
        dispatch(changeParentField(parentId, data))
        setChangeValue(false);
    }

    const clickToChangeHandler = () => {
        setChangeValue(true);
    }

    const inputChangeHandler = ({target}) => {
        setChangeValue(true)
        if(field === "email") {
            return setInputValue(target.value.replace("mailto:","").toLowerCase().replace(" ",''))
        }
        setInputValue(target.value)
    }

    return (
        <div className="student-card__input-field">
        {!!inputValue  && !changeValue
            ?
            <span onClick={clickToChangeHandler}>{startValue}</span>
            :
            <form onSubmit={formSubmitHandler}>
                <input className="student-card__field-tag" name={field} type={field === "email" ? "email" : "text"} value={inputValue || ""} onClick={inputChangeHandler} onChange={inputChangeHandler}/>
                <button className="profile__submit-btn" type="submit">Сохр</button>
            </form>
            }
        </div>
    )
}

export default ParentDataField