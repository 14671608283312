import React, { forwardRef, useImperativeHandle, useState } from "react";

const AlarmComponent = forwardRef((props, ref) => {
    const [showing, setShowing] = useState(false);

    const clickHandler = () => {
        setShowing(false)
    }
    
    
    useImperativeHandle(ref, ()=>({
        alarmCall(){
            setShowing(true)
        }
    }))


    return(
        showing && <div className="alarm__containers" onClick={clickHandler}>
            <div className="alarm-wrap" onClick={evt => {evt.stopPropagation()}}>
                <div className="alarm__description">
                    {props.description}
                </div>
                <div className="alarm__button-container">
                    <button onClick={props.cb} className="alarm__button alarm__button--yes">Да, прекрасно</button>
                    <button onClick={clickHandler} className="alarm__button alarm__button--no">Отмена</button>
                </div>
            </div>
        </div>
    )
});

export default AlarmComponent;