import { Link, useParams } from "react-router-dom";
import useFetch from '../../../hooks/useFetch';
import { useEffect, useState } from "react";
import dateConverter from '../../../service/dateConverter';
import convertSum from "../../../service/sumConverter";
import $api from "../../../service/api-service";


const BalanceCard = () => {
    const { studentId } = useParams();
    const [{response}, doFetch] = useFetch('/balance/'+studentId);
    const [balanceDate, setBalanceData] = useState({dataArray: [], sum: 0});

    useEffect(()=>{
        doFetch()
    },[doFetch])

    useEffect(()=>{
        if(!response) return;
        setBalanceData(response);
    },[response])

    const delClickHandler = ({target}) => {
        $api.delete('/outcome/'+target.dataset.id)
        .then(()=>doFetch())
    }

    return(
        <div>
            <Link to={'/archive/'+studentId}>
                Назад
            </Link>
            <div className="balance-card__balance-sum">
                Итог: <b>{convertSum(balanceDate.sum)} руб</b>
            </div>
            <table className="balance-card__table">
                <tbody>
                {balanceDate.dataArray.map(el=>{
                    return(
                    <tr className={el.type !== "outcome" ? "balance-card--income" : ""} key={el._id}>
                        <td className="balance-table__sum">
                            {el.amount} руб
                        </td>
                        <td>
                            { dateConverter(el.date) }
                        </td>
                        <td className="balance-table__info">
                            { el.info } 
                        </td>
                        <td>
                            {
                                (!el.date && el.type === "outcome") &&
                                <button data-id={el._id} onClick={delClickHandler}>x</button> 
                            }
                        </td>
                    </tr>    
                        )
                })}
                </tbody>
            </table>
        </div>
    )
}

export default BalanceCard;