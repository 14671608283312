import React, { useEffect } from "react";
import useFetch from '../hooks/useFetch.js';

const LessonHoursComponent = ({ isChecked, hours, lessonID}) => {

    const [{response}, doFetch] = useFetch('/lesson/hours/'+lessonID)

    const hourChangeHandler = ({target}) => {
        doFetch({
            method: 'put',
            data: {
                hours: target.value
            }
        })   
    }

    useEffect(()=>{
        if(!response) {
            return;
        }

    },[response])


    return(
        <div className="lesson-item__hours-wrap">
            <input type="number" disabled={isChecked} className="lesson__hours" step="0.25" defaultValue={hours} onChange={hourChangeHandler} />
        </div>
    )
}

export default LessonHoursComponent;