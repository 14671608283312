import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFetch from '../../../hooks/useFetch.js';
import { StudentDataActionCreator } from "../../../store/actions/students.js";

const StudentDataField = ({studentID, startValue="", field}) => {
    const [changeValue, setChangeValue] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const studentState = useSelector(state=>state.student);
    const dispatch = useDispatch();
    
    const [{response}, doFetchUpdate] = useFetch(`/student/${studentID}`);

    const formSubmitHandler = (evt) =>{
        evt.preventDefault();
        doFetchUpdate({
            method: "patch",
            data: {
                studentData: {
                    [field]: inputValue,
                }
            }
        })
    }

    useEffect(()=>{
        if(!response){
            return;
        }
        dispatch(StudentDataActionCreator(response));
        setChangeValue(false);
    },[response, dispatch])

    useEffect(()=>{
        setInputValue(startValue)
        setChangeValue(false)
    },[studentState, startValue])

    const inputChangeHandler = ({target}) => {
        setChangeValue(true)
        if(field==="email") {
            return setInputValue(target.value.replace("mailto:","").toLowerCase().replace(" ",''))
        }
        setInputValue(target.value)
    }

    const clickToChangeHandler = () => {
        setChangeValue(true);
        setInputValue(startValue)
    }

    return(
        <div className="student-card__input-field">
            {!!inputValue  && !changeValue
                ?
                <span onClick={clickToChangeHandler}>{studentState[field]}</span>
                :
                <form onSubmit={formSubmitHandler}>
                    <input className="student-card__field-tag" name={field} type='text' value={inputValue || ""} onClick={inputChangeHandler} onChange={inputChangeHandler}/>
                    <button className="profile__submit-btn" type="submit">Сохр</button>
                </form>
                }
        </div>

    )
}

export default StudentDataField;