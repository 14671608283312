import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {NavLink, Link} from 'react-router-dom'
import TeacherInfo from './teachers-info';
import Logo from '../img/logo.png';

const Topbar = () => {
  const dispatch = useDispatch();
  const currentUserState = useSelector(state => state.currentUser);
  const isLead = currentUserState?.currentUser?.role === 'teamlead';
  const isAdmin = currentUserState?.currentUser?.isAdmin;
  const isLoggedIn = currentUserState.isLoggedIn;
  
  return (
    <nav className="navbar navbar-light">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <div className="top-bar__logo-wrap">
            <img src={Logo} alt="Смэш – платформа для преподаватлей YesCoding"/>
          </div>
        </Link>
        <ul className="nav navbar-nav pull-xs-right">
          {isLoggedIn &&
          <>
          { isAdmin &&
          <li className="nav-item">
            <NavLink to="/reviews" className="nav-link" >
              Отзывы
            </NavLink>
          </li>}
          {(isAdmin || isLead) &&
            <ul>
              <li className="nav-item">
                <NavLink to="/creation" className="nav-link" >
                  Создать
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/teachers" className="nav-link" >
                  Училки
                </NavLink>
              </li>
            </ul>
          }
          {isAdmin &&
          <ul>
            <li className="nav-item">
              <NavLink to="/archive" className="nav-link" >
                Управление
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/schedule" className="nav-link" >
                Расписание
              </NavLink>
            </li>
          </ul>
          }
          {isAdmin &&
            <li className="nav-item">
              <NavLink to="/lessons-check" className="nav-link" >
                Отметить
              </NavLink>
            </li>
          }
          <li className="nav-item">
            <NavLink to="/disk" className="nav-link" >
              Диск
            </NavLink>
          </li>
          {!isAdmin &&
          <>
            <li className="nav-item nav-item--lesson">
              <NavLink to="/reviews" className="nav-link nav-link--lesson" >
                  Отзывы
              </NavLink>
          </li>
          <li className="nav-item nav-item--lesson">
            <NavLink to="/lessons" className="nav-link nav-link--lesson" >
              Уроки
            </NavLink>
          </li>
          </>}
          <div className="nav-bar__decor"></div>
          <li className="nav-item nav__user-name">
          <NavLink to={'/profile'}>{currentUserState.currentUser.userName}</NavLink>
          </li>
          <li>
            {
            !isAdmin &&
            <TeacherInfo teacher={currentUserState.currentUser}/> }
          </li>
          <li className="nav-item sign-btn">
           <button className="exit__btn" onClick={()=>{dispatch({type: 'SET_UNAUTHORIZED'})}} >
              Выход
            </button>
          </li>
          </> }
        </ul>
      </div>
    </nav>
  )
}

export default Topbar
