const defaultState = {
    filesList: [],
    dirStack: [],
    isLoading: false,
    popupDisplay: 'none',
    currentDir: null
}

export const filesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_FILES":
            return {...state, filesList: action.payload}
        case "SET_FILES_LOADING":
            return {...state, isLoading: action.payload}
        case "SET_POPUP_DISPLAY": 
            return {...state, popupDisplay: action.payload}
        case "ADD_FILE": 
            return {...state, filesList: [...state.filesList, action.payload]}
        case "PUSH_TO_STACK": 
            return {...state, dirStack: [...state.dirStack, action.payload]}
        case "SET_CURRENT_DIR": 
            return {...state, currentDir: action.payload}
        case "DELETE_FILE":
            return {...state, filesList: [...state.filesList.filter(file => file._id !== action.payload)]}
        default:
            return state
    }
}

export const setPopupDisplay = (display) => ({type: "SET_POPUP_DISPLAY", payload: display});
export const addFile = (file) => ({type: "ADD_FILE", payload: file});
export const pushToStack = (dir) => ({type: 'PUSH_TO_STACK', payload: dir});
export const setCurrentDir= (dir) => ({type: 'SET_CURRENT_DIR', payload: dir});
export const deleteFileAction = (dirId) => ({type: 'DELETE_FILE', payload: dirId});