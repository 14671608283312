import { useState } from "react";
import TeacherSchedule from "../components/teacherSchedule";
import TeacherSelect from "../components/teacherSelect";
import { useSelector } from "react-redux";

const SchedulePage = () => {
    const [teacherId, setTeacherId] = useState('');
    const filialAccess = useSelector(state => state.currentUser.currentUser?.userRole?.filialAccess);
    const [bodyData, setBodyData] = useState({
        filial: filialAccess[0]._id,
        absoluteFilial: false
    })

    const changeHandler = ({ target }) => {
        setBodyData({ ...bodyData, [target.name]: target.value });
    }

    const teacherChangeHandler = (evt) => {
        setTeacherId(evt.target.value);
    }

    const checkboxHandler = (evt) => {
        setBodyData({...bodyData, absoluteFilial: evt.target.checked});
    }

    return(
        <div className="container">
            <h1>Рассписание</h1>
            <div className="schedule__filter-line">
                {filialAccess.length > 1 &&
                        <div>
                            <p className='lesson-mgmnt__label'>Филиал</p>
                            <select value={bodyData.filial} onChange={changeHandler} name='filial' className="teachers-select filial_select">
                                <option value={"all"}>Все</option>
                                {filialAccess?.map(el => {
                                    return <option value={el._id} key={el._id}>{el.name}</option>
                                })}
                            </select>
                        </div>
                    }
                <div>
                    <p className='lesson-mgmnt__label'>Преподаватель</p>
                    <TeacherSelect changeHandler={teacherChangeHandler}/>
                </div>
                {teacherId && 
                    <div>
                        <p> все филиалы</p> 
                        <input type="checkbox" value={bodyData.absoluteFilial} onChange={checkboxHandler}/>
                    </div>
                }
            </div>
            <TeacherSchedule absoluteFilial={bodyData.absoluteFilial} filial={bodyData.filial} managerView={true} teacherId={teacherId}/>
        </div>
    )
}

export default SchedulePage;