import { useState } from "react";
import $api from "../service/api-service";

const ProgressName = ({name, id}) => {
    const [showInput, setShowInput] = useState(!name);
    const [inputValue, setInputValue] = useState(!!name ? name :"" );

    const submitHandler = (evt) => {
        evt.preventDefault();
        $api.patch('/progress/change-name/'+id,
            {name: inputValue})
            .then(()=>{
                setShowInput(false);
            });
    };

    const nameClickHandler = () => {
        setShowInput(true);
    }

    return (
        <div>
            {!showInput ? 
            <b onClick={nameClickHandler}>{inputValue}</b>
            :
            <form onSubmit={submitHandler}>
                <input value={inputValue} onChange={({target})=>setInputValue(target.value)} type="text" />
            </form>
            }
        </div>
    )
}

export default ProgressName;