import React, { useCallback, useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch.js';

const StudentSearch = ({chandgeDataFunc}) => {

    const [students, setStudents] = useState("")
    const [keyword, setKeyWord] = useState('')
    const [{response}, doFetch] = useFetch('/group/search/'+keyword);

    const changeHandle = ({target}) => {
        if(target.value === ""){
            setStudents("");
            return;
        }
        setKeyWord(target.value);
        doFetch()
    }

    const resultClickHandler = useCallback((id, studentName) =>{
        setStudents("")
        document.getElementById('student_search').value = studentName;
        chandgeDataFunc({target:{name: 'groupID', value: id}})
    },[chandgeDataFunc])
    
    useEffect(()=>{
        if(!response) {
            return;
        }
        
        setStudents(response)
    },[response])

    return(
        <div>
            <input autoComplete='off' type="text" placeholder="Введите имя группы..." id="student_search" onChange={changeHandle}/>
            <StudentSearchItem resultClickHandler={resultClickHandler} studentsArr={students}/>
        </div>
    )
}


export const StudentSearchItem = ({studentsArr, resultClickHandler}) =>{
    const handleClick = (id, studentName) => () => {
        resultClickHandler(id, studentName);
    };

    return(
        <div className="searching-student__wrap">
            {Array.isArray(studentsArr) && studentsArr.map(el => {
                return(
                    <div key={el._id} className="search-result__item" onClick={handleClick(el._id, el.name)}>
                        {el.name}
                    </div>
                )
            })}
        </div>
    )}

export default StudentSearch;