import $api from "../../service/api-service";

export const FetchFullGroup = (groupId = "") => {
    return dispatch => {
        dispatch({type: "SET_GROUP_LOADING", payload: true})
        $api.get('/group/current/'+groupId)
            .then((response => {
                dispatch({type: 'SET_INFO_LESSONS', payload: response.data});
            }))
            .then(()=>{
                $api.get('/onetime/group/'+groupId)
                .then(response=>{
                    dispatch({type: 'SET_GROUP_ONETIMERS', payload: response.data})
            })
            })
            .catch(err=>{
                if(err.response?.status === 401) {
                    dispatch({type: "SET_UNAUTHORIZED"});
                }
            })
            .finally(()=>{
                dispatch({type: "SET_GROUP_LOADING", payload: false})
            })
    }
}

export const FetchOneTimers = (groupId = "") => {
    return dispatch => {
        $api.get('/onetime/group/'+groupId)
            .then(response=>{
                dispatch({type: 'SET_GROUP_ONETIMERS', payload: response.data})
            })
    }
}