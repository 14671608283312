import $api from "../../service/api-service";

export const FetchLessons = (month, teacherId = "", year, isChecked="all", filial) => {
    return dispatch => {
        dispatch({type: "SET_LOADING", payload: true});
        $api.post('/group/all', { month, teacherId, year, isChecked, filial })
            .then((response => {
                dispatch({type: 'SET_LESSONS_LIST', payload: response.data});
            }))
            .then(()=>{
                const teacher = teacherId ? teacherId : '1111'
                $api.get(`/onetime/get-by-teacher/${teacher}/${year}/${month}/${isChecked}`)
                .then((response)=>{
                    dispatch({type: 'SET_ONETIME_LIST', payload: response.data});
                })
            })
            .catch(err=>{
                if(err.response.status === 401) {
                    dispatch({type: "SET_UNAUTHORIZED"});
                }
            })
            .finally(()=>{
                dispatch({type: "SET_LOADING", payload: false})
            })
    }
}

export const SaveAttandance = (lesson, studentsAttandance, updateFunc=()=>{}) => {
    return dispatch => {
        $api.put('/lesson/'+lesson._id, {studentsAttandance, lesson})
        .then(()=>{
            updateFunc();
        })
            .catch(err=>{
                if(err.response.status === 401) {
                    dispatch({type: "SET_UNAUTHORIZED"});
                }
            })
    }
}

export const CreateOneTime = (data) => {
    return dispatch => {      
        $api.post(`/onetime/create`, data)
            .then((response)=>{
                dispatch({type: 'ADD_ONETIME_LIST', payload: response.data});
            })
    }
}

export const DeleteOneTime = (id) => {
    return dispatch => {      
        $api.delete(`/onetime/${id}`)
            .then((response)=>{
                dispatch({type: 'PULL_ONETIME_LIST', payload: id});
            })
    }
}