import React, { useEffect } from "react";
import useFetch from '../hooks/useFetch.js';

const OnTimeCheckBox = ({isChecked, oneTimeID}) => {
    const [{response}, doFetch] = useFetch('/onetime/change-check-status/'+oneTimeID);

    const changeHandler = ({target}) => {
        doFetch({
            method: "post",
            data: {
                isChecked: target.checked
            }
        })
    }

    useEffect(()=>{
        if(!response) {
            return;
        }

    },[response])

    return(
        <div>
            <input onChange={changeHandler} type="checkbox" defaultChecked={isChecked}/>
        </div>
    )
}

export default OnTimeCheckBox;