const defaultState = {
    isLoading: false,
    lastNotificationDate: "",
    lastNotificationId: ""
}

export const paymentNotificationReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_PAYMENT_LOADING":
            return {
                ...state, isLoading: action.payload
            }
        case "SET_LAST_NOTIFICATION":
            return {
                ...state, 
                lastNotificationDate: action.payload.lastNotificationDate, 
                lastNotificationId: action.payload.lastNotificationId
            }

        default:
            return state
    }
}

export const lastNotificationSelector = state => ({notificationDate: state.paymentNotification.lastNotificationDate, notificationId: state.paymentNotification.lastNotificationId});
export const notificationLoadingSelector = state => state.paymentNotification.isLoading;