import { useSelector } from 'react-redux';
import GroupElem from './groupElem.js';


const GroupList = ({ isManager = false }) =>{
    const lessons = useSelector(state => state.lessons) 
    return(
        <div className="group-list">
            {lessons.lessonsList.map(el => 
                <GroupElem key={el._id} initGroupData={el} isManager={isManager}/>
            )} 
        </div>
    )
}

export default GroupList