import React from "react";
import loadingGif from "../img/loading.gif";

const Loading = ({width=80, style}) => {

    return(
        <div style={style} className="loading-wrap">
            <div className="loading-component">
                <img alt="загрузка" width={width} src={loadingGif} />
            </div>
        </div>
    )
}

export default Loading;