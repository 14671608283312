import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChangeLessons from '../components/changeLessons.js';
import GroupList from '../components/groupList.js';
import Loading from '../components/loading.js';
import OneTimersForMngmnt from '../components/oneTimersForMngmnt.js';
import TeacherSelect from '../components/teacherSelect.js';
import createCurMount from '../service/monthCreator.js';
import { FetchLessons } from '../store/actions/lessons.js';

const LessonManagementPage = () => {
    const filialAccess = useSelector(state => state.currentUser.currentUser?.userRole?.filialAccess);
    const lessonsState = useSelector(state => state.lessons);
    const dispatch = useDispatch();

    const [bodyData, setBodyData] = useState({
        teacherId: "",
        checkStatus: "unchecked",
        month: createCurMount(),
        year: new Date().getFullYear(),
        filial: "all"
    })

    const printBtnHandler = useCallback(() => {
        dispatch({ type: "SET_MONTH", payload: bodyData.month });
        dispatch({ type: "SET_YEAR", payload: bodyData.year });
        dispatch({ type: "CHANGE_CHECK_STATUS", payload: bodyData.checkStatus === "unchecked" });
        dispatch(FetchLessons(
            bodyData.month,
            bodyData.teacherId,
            bodyData.year,
            bodyData.checkStatus,
            bodyData.filial
        ));
    }, [bodyData, dispatch])

    const changeHandler = ({ target }) => {
        setBodyData({ ...bodyData, [target.name]: target.value });
        dispatch({ type: "SET_LESSONS_LIST", payload: [] });
    }

    return (
        <div className="container">
            <h1>Общая ведомость занятий</h1>
            <div className="lesson-mgmnt__wrap">
                {filialAccess.length > 1 &&
                    <div>
                        <p className='lesson-mgmnt__label'>Филиал</p>
                        <select value={bodyData.filial} onChange={changeHandler} name='filial' className="teachers-select filial_select">
                            <option value={"all"}>Все</option>
                            {filialAccess?.map(el => {
                                return <option value={el._id} key={el._id}>{el.name}</option>
                            })}
                        </select>
                    </div>
                }
                <div>
                    <p className='lesson-mgmnt__label'>Преподаватель</p>
                    <TeacherSelect changeHandler={changeHandler} />
                </div>
                <div>
                    <p className='lesson-mgmnt__label'>Статус</p>
                    <select className="lesson-mgmnt__status" name="checkStatus" value={bodyData.checkStatus} onChange={changeHandler}>
                        <option value="checked">Заведенные</option>
                        <option value="unchecked">Незаведенные</option>
                        <option value="all">Все</option>
                    </select>
                </div>
                <div>
                    <p className='lesson-mgmnt__label'>Месяц</p>
                    <select className="lesson-mgmnt__mounth " name="month"
                        onChange={changeHandler}
                        value={bodyData.month}
                    >
                        <option value="01">Январь</option>
                        <option value="02">Февраль</option>
                        <option value="03">Март</option>
                        <option value="04">Апрель</option>
                        <option value="05">Май</option>
                        <option value="06">Июнь</option>
                        <option value="07">Июль</option>
                        <option value="08">Август</option>
                        <option value="09">Сентябрь</option>
                        <option value="10">Октябрь</option>
                        <option value="11">Ноябрь</option>
                        <option value="12">Декабрь</option>
                    </select>
                </div>
                <div>
                    <p className='lesson-mgmnt__label'>Год</p>
                    <input type='number' onChange={changeHandler} name="year" className="lesson-mgmnt__year" defaultValue={lessonsState.year} min="2020" max="2050" step="1" />
                </div>
                <button className="mgmnt__btn" onClick={printBtnHandler}>Вывести</button>
            </div>
            <h2>Ван Таймы</h2>
            <OneTimersForMngmnt currentMounth={bodyData.month} onlyUnchecked={bodyData.checkStatus === "unchecked"} updateFunction={printBtnHandler} teacherID={bodyData.teacherId ? bodyData.teacherId : "1111"} />
            {lessonsState.lessonsList.length > 0 && bodyData.teacherId!=="" && <ChangeLessons userId={bodyData.teacherId} />}
            <h2>Постоянные уроки</h2>
            <GroupList isManager={true} />

            {lessonsState.isLoading && <Loading />}
        </div>
    )
}

export default LessonManagementPage;
