import { useState, useEffect, useCallback } from 'react';
import $api from '../service/api-service.js';
import { useDispatch } from 'react-redux';


const FetchHook =  url => {
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [options, setOptions] = useState({})

  const dispatch = useDispatch();

  const doFetch = useCallback((options = {}) => {
    setOptions(options)
    setIsLoading(true)
  }, [])

  useEffect(() => {
    let skipGetResponseAfterDestroy = false
    if (!isLoading) {
      return
    }

    $api.request(url, options)
      .then(res => {
        if(!skipGetResponseAfterDestroy){
          setResponse(res.data)
          setIsLoading(false)
        }
      })
      .catch((error) => {
          if(!skipGetResponseAfterDestroy){
            setIsLoading(false)
            setError(error)
            if(error.response && error.response.status === 401) {
              dispatch({type: 'SET_UNAUTHORIZED'})
            }
          }
        })

      return () => {
        skipGetResponseAfterDestroy = true
      }
  }, [isLoading, options, url, dispatch])

  return [{isLoading, response, error}, doFetch]
}
export default FetchHook
