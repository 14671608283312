import React, { useEffect } from "react";
import useFetch from '../hooks/useFetch.js';

const AddToGroupBtn = ({studentID, groupID, updateFunc}) => {
    const [{response}, doFetch] = useFetch('/group/addstudent')

    const BtnClickHandler = () => {
        doFetch({
            method: "post",
            data: {
                studentID: studentID,
                groupID: groupID
            }
        })
    }

    useEffect(()=>{
        if(!response) {
            return;
        }

        updateFunc();
    },[response, updateFunc])

    return(
        <button onClick={BtnClickHandler}>+</button>
    )
}

export default AddToGroupBtn;