import React from "react";
import AttandanceSelect from "./attandanceSelect";
import LessonDelComponent from "./lessonDelComponent";
import LessonHoursComponent from "./lessonHoursComponent";
import LessonsCheckComponent from "./lessonsCheckComponent";
import LessonsDate from "./lessonsDate";

const LessonsListComponent = ({ lessons, isManager, updateFunction, students, groupTeacher }) => {

    return(
        lessons.map((lessonData, i) => {
            const lessonTeacher = typeof lessonData.teacher == 'object' ? lessonData.teacher._id : lessonData.teacher;
            const notYour = groupTeacher !== lessonTeacher
            const lastElem = lessons.length-1 <= i;
            return <LessonsListItem key={lessonData._id} students={students} isManager={isManager} updateFunction={updateFunction} lessonTeacher={lessonTeacher} notYour={notYour} lastElem={lastElem} lessonData={lessonData} />
        })
    )
}

const LessonsListItem = ({lessonData, lastElem, notYour, updateFunction, isManager, students}) => {

    return(
        <div className={"lesson__item " + (notYour ? "not-your" : "")} key={lessonData._id}>
            <div className="lessons-item__header ">
                <LessonDelComponent isChecked={lessonData.isChecked} updateFunction={updateFunction} lessonId={lessonData._id} />
                {
                !lessonData.isChecked ?
                <LessonsDate lessonData={lessonData} />
                :
                <span className="lesson-item__date">{lessonData.date.slice(5, 10).split("-").reverse().join('.')}</span>
                }
            </div>
            <div className="lesson__attandance-wrap">
            {students && 
                <AttandanceSelect 
                    updateFunction={updateFunction} 
                    lastElem = {lastElem}
                    students={students} 
                    lesson={lessonData} 
                    callBackFunc={updateFunction} 
                    lessonId={lessonData._id} />}
                <LessonHoursComponent 
                    isChecked={lessonData.isChecked} 
                    hours={lessonData.hours} 
                    lessonID={lessonData._id}/>
            </div>
            <LessonsCheckComponent 
                updateFunction={updateFunction} 
                isManager={isManager} 
                isChecked={lessonData.isChecked} 
                lessonID={lessonData._id}/>
        </div>
    )
}

export default LessonsListComponent;