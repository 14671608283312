import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { filialsSelect } from "../../store/currentUser";
import ReviewCounter from "./include/reviewCounter";
import TeacherHourInFilial from "./include/teacherHourInFilial";
import AttendanceAmountList from "./include/attendenceAmountList";

const AnalyticsPage = () => {
    const filials = useSelector(filialsSelect);

    return(
        <main className="container">
            <h1>Аналитика</h1>
            <Route path={'/anltcs'} exact>
                <div className="analytics__filials-wrap">
                    { filials.map(el=>{
                        return <TeacherHourInFilial key={el._id} filial={el} />
                    }) }
                </div>
            </Route>
            <Route path={"/anltcs/reviews"}>
                <ReviewCounter />
            </Route>
            <Route path={"/anltcs/attendances"}>
                <AttendanceAmountList />
            </Route>
        </main>
    )
}

export default AnalyticsPage;