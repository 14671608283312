import { useState } from "react";
import DeleteOneTimeBtn from "./deleteOneTimeBtn.js";
import OneTimeCheckbox from "./oneTimeCheckbox.js";
import Loading from "./loading.js";
import { useSelector } from "react-redux";
import OneTimeCreation from "./oneTimeCreation.js";
import { Link } from "react-router-dom";
   
const OneTimersForMngmnt = () => {
    const oneTimes = useSelector(state => state.lessons.oneTimesList);
    const isLoading = useSelector(state => state.lessons.isLoading);

    const [open, setOpen] = useState("");

    const openHandler = () => {
        setOpen(p=>p === "" ? "onetime__list--mngmnt--opened": "")
    }

    return(
        <div>
            {isLoading && <Loading />}
            <div className={`onetime__list--mngmnt ${open}`}>
            <OneTimeCreation isManager={true}/>
                {
                    oneTimes.map(el=>{
                        return (
                            <div className="onetime-list__item" key={el._id}>
                                <DeleteOneTimeBtn key={el._id} onetimeID={el._id}/>
                                <div className="onetime-list__date">{el.date.slice(5, 10).split("-").reverse().join('.')}</div>
                                {el.lessonType !== "test" ? <>
                                    <div className="onetime-list__lesson-type"> Замена </div>
                                    {el.groupID && <Link to={'/hour-info/?'+el.groupID} className="onetime-list__group-name">{el.groupName}</Link>}
                                </>:
                                 <div className="onetime-list__lesson-type"> Пробное </div>
                                }
                                <div>{el.filial.name}</div>
                                <div className="onetime-list__group-name">{el.teacherName}</div>
                                <div><i>{el.comment}</i></div>
                                <div>
                                    <i>{el.hours}</i>
                                </div>
                                <OneTimeCheckbox oneTimeID={el._id} isChecked={el.isChecked}/>
                            </div>
                        )
                    })
                }
            </div>
            {oneTimes.length > 5 && <button onClick={openHandler}> {open==="" ? "Показать все" : "Свернуть"}</button>}
        </div>
    )
}

export default OneTimersForMngmnt;