import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { UpdateUserField } from '../store/actions/auth';

const UserProfileField = ({fieldData, fieldKey}) => {
    const [isCorrecting, setIsCorrecing] = useState(!fieldData);
    const [showBtn, setShowBtn] = useState(false);
    const [inputValue, setInputValue] = useState(fieldData + "");
    const dispatch = useDispatch();

    useEffect(()=>{
        setIsCorrecing(!fieldData);
        setInputValue(fieldData + "");
    },[fieldData])

    const submitHandler = (evt) => {
        evt.preventDefault()
        const body = {
            field: fieldKey,
            value: inputValue
        }
        dispatch(UpdateUserField(body));
        if(inputValue.length > 0){
            setIsCorrecing(false)
        }
        setShowBtn(false);
    }

    const inputChangeHandler = ({target}) => {
        setShowBtn(true);
        setInputValue(target.value);
    }

    const valueClickHandler = () => {
        setIsCorrecing(true);
        setShowBtn(true);
    }
    
    return (
            <>
                {
                    isCorrecting ?
                    <form onSubmit={submitHandler}>
                        <input value={inputValue} onChange={inputChangeHandler} type="text"/>
                        { showBtn && <button className='profile__submit-btn' type='submit'>Сохранить</button> }
                    </form> :
                    <span className='profile__value' onClick={valueClickHandler}>
                        {fieldData}
                    </span>
                }
            </>
        )
}

export default UserProfileField;