import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { updatePrice } from "../store/actions/students";
import Switch from "./switch";
import useFetch from '../hooks/useFetch.js';

const Price = ({userId, groupId }) => {
    const [priceData, setPriceData] = useState({
        isAcademic: "", sub: true, amount: "", _id: ""
    });
    const [isAmountChanging, setIsAmountChanging] = useState(false);
    const dispatch = useDispatch();

    const [{response}, doFetch] = useFetch(`/price/${groupId}/${userId}`);

    useEffect(()=>{
        doFetch();
    },[doFetch])

    useEffect(()=>{
        if(!response) return;
        setPriceData(response);
    },[response])

    const subChangeHandler = ({target}) => {
        setPriceData(p=>({...p, sub: target.checked}));
        dispatch(updatePrice(userId, priceData._id, {sub: target.checked}))
    }

    const academicChangeHandler = ({target}) => {
        setPriceData(p=>({...p, isAcademic: target.checked}));
        dispatch(updatePrice(userId, priceData._id, {isAcademic: target.checked}));
    }

    const onPriceSumChange = (evt) => {
        evt.preventDefault();
        dispatch(updatePrice(userId, priceData._id, {amount: priceData.amount}));
        setIsAmountChanging(false);
    }

    const onAmountChange = ({target}) => {
        if(!isAmountChanging) setIsAmountChanging(true);
        setPriceData(p=>({...p, amount: target.value}));
    }

    return(
        <table className="price__table">
            <tbody>
                <tr>
                    <td>
                        Абонемент:
                    </td>
                    <td>
                        <Switch name="sub" value={priceData.sub} onChageFn={subChangeHandler} />
                    </td>
                </tr>
                <tr>
                    <td>
                        Академы:
                    </td>
                    <td>
                        <Switch name="isAcademic" value={priceData.isAcademic} onChageFn={academicChangeHandler} />
                    </td>
                </tr>
                <tr>
                    <td>
                        Цена за час:
                    </td>
                    <td>
                        <form className="price__amount-form" onSubmit={onPriceSumChange}>
                            <input className="price__amount-field" name='amount' type='text' value={priceData.amount} onChange={onAmountChange}/>
                            {isAmountChanging && <button className="price__amount-btn" type='submit'>✓</button>}
                        </form>
                    </td>
                </tr>

            </tbody>
        </table>
    )
}

export default Price;