export const ShowMessage = (message, emotional = "neutral") => {
    return dispatch => {
        dispatch({type: "SET_MESSAGE", payload: {content: message, emotional: emotional}})
    }
}

export const ClearMessage = (message) => {
    return dispatch => {
        dispatch({type: "CLEAR_MESSAGE", payload: ""})
    }
}