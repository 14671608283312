import React, { useCallback, useEffect, useState } from "react";
import AddToGroup from "./addToGroupComponent";
import ArchiveGroupBtn from "./archiveGroupBtn";
import LessonCreation from "./lessonCreation";
import StudentsList from "../pages/archivePage/include/studentsListComponent";
import useFetch from '../hooks/useFetch.js';
import Loading from "./loading";
import LessonsListComponent from "./lessonsListComponent";
import TeacherChange from "./teacherChange";
import GroupNameComponent from "./groupName";
import { useDispatch, useSelector } from "react-redux";
import GroupHoursList from "./groupHoursList";
import ReviewUploadBtn from "./reviewUploadBtn";
import LessonsLength from "../pages/hourInfoPage/include/lessonsLength";
import GroupSchedule from "./groupSchedule.js";
import CreateScheduleInGroupElem from "./createScheduleInGroupElem.js";

const GroupElem = ({initGroupData, isManager}) => {
    const lessonsState = useSelector(state=>state.lessons)
    const [{response, isLoading}, doFetch] = useFetch(`/group/current/${initGroupData._id}/${lessonsState.month}/${lessonsState.year}`);
    const [groupData, setGroupData] = useState(initGroupData);

    const dispatch = useDispatch()

    const updateFunction = useCallback(() => {
        doFetch();
    },[doFetch])

    useEffect(()=>{
        setGroupData(initGroupData);
    },[initGroupData])

    const filterUnchecked = useCallback(() => {
        if(!lessonsState.onlyUnchecked) {
            return groupData.lessons
        }

        return groupData.lessons.filter(el => !el.isChecked);        
    }, [groupData, lessonsState.onlyUnchecked]);

    const filteredLessons = filterUnchecked();
    const amountOfHours = filteredLessons?.reduce((acc, el)=>{
        return acc+el.hours;
    },0)
    
    useEffect(()=>{
        if(!response) {
            return;
        }

        if(lessonsState.onlyUnchecked && response.lessons.filter(lesson=>!lesson.isChecked).length <= 0) {
            return dispatch({type: "REMOVE_FROM_LESSONS_LIST", payload:response._id});
        }

        setGroupData(response);
    },[response, dispatch, lessonsState.onlyUnchecked, groupData._id])
    
    return (
        <div id={groupData._id} className="grop__container--with-review">
            <div className="group-wrap" key={initGroupData._id+"lesson"}>
                <div className="group__schedule-wrap">
                    <CreateScheduleInGroupElem groupId={groupData._id} updateFunc={updateFunction}/>
                    {groupData.groupSchedule && <GroupSchedule updateFunc={updateFunction} schedule={groupData.groupSchedule} />}
                </div>
                <div className="group__mgt">
                    {isLoading && <Loading />}
                    {groupData.specialSalary === 'OnOff' && <div className="group__online-indicator"></div>}
                    <ArchiveGroupBtn updateFunc={updateFunction} groupID={groupData._id}/>
                    <GroupHoursList updateFunc={updateFunction} groupID={groupData._id} isManager={isManager} progress={groupData.progress}/>
                    <ReviewUploadBtn courses={groupData.progress} teacher={groupData.teacher} groupId={groupData._id} students={groupData.students} />
                    {isManager ?
                        <LessonsLength groupId={groupData._id}  lessonsLength={groupData.lessonsLength}/> :
                        <span>Длит. урока: {groupData.lessonsLength} час</span>
                    }
                </div>
                <div className="group__teacher">
                    {isManager && 
                        <TeacherChange groupTeacher={groupData.teacher} groupID={groupData._id} updateFunction={updateFunction}/> }
                    </div>
                {isManager ? 
                    <GroupNameComponent updateFunction={updateFunction} groupID={groupData._id} groupName={groupData.name}/> : 
                    <h3 className="group__name">{groupData.name}</h3>   
                }
                <div className="group-block">
                    <div>
                        <StudentsList updateFunc={updateFunction} isManager={isManager} groupID={groupData._id} studnets={groupData.students} />
                        {isManager && <AddToGroup updateFunc={updateFunction} groupID={groupData._id}/>}
                    </div>
                    <LessonsListComponent  groupTeacher={initGroupData.teacher?._id} updateFunction={updateFunction} isManager={isManager} students={groupData.students} lessons = {filteredLessons} />
                    {
                        (groupData.students.map(el=>el.balance?.sum>0).reduce((acc, el)=>acc || el, false) || isManager ) && <>
                            <LessonCreation updateFunction={updateFunction} lastHour={filteredLessons[filteredLessons.length-1]?.hours} groupData={groupData}/>
                            <div className="group__amount-hours">{amountOfHours}</div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default GroupElem;