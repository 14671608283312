import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import $api from "../service/api-service";

const ChangeLessons = ({userId}) => {
    const [changeLessons, setChangeLessons] = useState([])
    const lessonsState = useSelector(state =>  state.lessons);

    useEffect(()=>{
        let isCancelled = false;
        $api.get(`lesson/teachers-changes/${userId}/${lessonsState.month}/${lessonsState.year}`)
            .then(response=>{
                if(!isCancelled) setChangeLessons(response.data);
            })
            
        return () => {
            isCancelled = true;
        };
    },[lessonsState.month, lessonsState.year, userId])

    if(changeLessons.length < 1) {
        return null
    }

    return(
        <div className="changers-wrap">
            <h3>Замены</h3>
            {changeLessons.map(el=>{
                return (
                    <div key={el._id}>
                        <b>{el.date.slice(5, 10).split("-").reverse().join('.')} </b> 
                        {el.groupId.name} – {el.hours} час</div>)
            })}
        </div>
    )
}

export default ChangeLessons;