import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import $api from "../service/api-service"
import Loading from "./loading";

const HoursInfoNotes = () => {
    const groupInfoData = useSelector(state => state.groupInfo);
    const dispatch = useDispatch();
    const [areaData, setAriaData] = useState(groupInfoData.notes);
    const [isLoading, setIsLoading] = useState(false);

    const submitHandler = useCallback((evt)=>{
        setIsLoading(true);
        evt.preventDefault();
        $api.put('/group/change/notes/'+groupInfoData._id,
            {notesData: areaData})
            .then((data)=>{
                dispatch({type: 'SET_NOTES', payload: data.data.message});
            })
            .finally(()=>setIsLoading(false))
    },[groupInfoData, areaData, dispatch]);

    return(
        <form className="notes-form" onSubmit={submitHandler}>
            <h3>Заметка</h3>
            <textarea rows={2} cols={30} type="textaria" value={areaData} onChange={({target})=>setAriaData(target.value)} />
            <div>
                {!isLoading && <button type="submit">Сохранить</button>}
                {isLoading && <Loading />}
                {groupInfoData.notes !== areaData && "не сохраненно"}
            </div>
        </form>
    )
};

export default HoursInfoNotes;