import React, { useCallback, useEffect, useState } from "react";
import useFetch from '../hooks/useFetch.js';

const GroupNameComponent = ({groupName, groupID, updateFunction}) => {
    const [changeState, setChangeState] = useState(false);
    const [inputData, setInputData] = useState(groupName);
    const [{response}, doFetch] = useFetch('/group/change/name/'+groupID)

    const clickHandler = () => {
        setChangeState(true);
    }

    const saveGroupName = useCallback((evt) => {
        evt.preventDefault();
        doFetch({
            method: 'put',
            data: {
                newName: inputData
            }
        })
    },[doFetch, inputData]);

    useEffect(()=>{
        if(!response) {
            return;
        }
        setChangeState(false)
    },[response])

    return(
        <div>
            {changeState ?
            <form onSubmit={saveGroupName}>
                <input className="group-name__changer" type="text" onChange={({target})=>{setInputData(target.value)}} value={inputData}/>
                <button type="submit">✓</button> 
            </form> :
            <h3 className="group__name" onClick={clickHandler} >{inputData}</h3>
            }
            
        </div>
    )
}

export default GroupNameComponent;