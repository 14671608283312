import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserProfileCheckbox from "../components/userProfileCheckBox";
import UserProfileField from "../components/userProfileField";
import $api from "../service/api-service";
import { GetUser } from "../store/actions/auth";

const ProfilePage = () => {
    const user = useSelector(state=>state.currentUser.fullUserInfo);
    const dispatch = useDispatch();
    const [hoursInLastMonth, setHours] = useState(0);


    useEffect(()=>{
        dispatch(GetUser());
    },[dispatch])

    useEffect(()=>{
        if(!user._id) {
            return;
        }
       $api.get(
       `/lesson/what-about-me/${user._id}/${new Date().getMonth()}/${new Date().getFullYear()}`
       ).then(({data})=>{
            setHours(data)
       })
    },[user._id])

    return(
        <div className="container">
            <h1>Профиль пользователя</h1>
            <div className='profile__line'>
                <b>Отчество: </b>
                <UserProfileField fieldKey={"patronymic"} fieldData={user.patronymic} />
            </div>
            <div className='profile__line'>
                <b>р/с банка: </b>
                <UserProfileField fieldKey={"bankNum"} fieldData={user.bankNum} />
            </div>
            <div className='profile__line'>
                <b>ИНН: </b>
                <UserProfileField fieldKey={"INN"} fieldData={user.INN} />
            </div>
            <div className='profile__line'>
                <b>БИК банка: </b>
                <UserProfileField fieldKey={"BIC"} fieldData={user.BIC} />
            </div>
            <div className='profile__line'>
                <b>Телеграм: </b>
                <UserProfileField fieldKey={"telegramLink"} fieldData={user.telegramLink} />
            </div>
            <div className='profile__line'>
                <b>Ник Дискорд:</b>
                <UserProfileField fieldKey={"discordName"} fieldData={user.discordName} />
            </div>
            <div className='profile__line'>
                <b>Номер Телефона: </b>
                <UserProfileField fieldKey={"phoneNumber"} fieldData={user.phoneNumber} />
            </div>
            <div className='profile__line'>
                <b>ВК: </b>
                <UserProfileField fieldKey={"vkLink"} fieldData={user.vkLink} />
            </div>
            <div className='profile__line' >
                <b>Готов офлайн (только екб)</b>
                <UserProfileCheckbox fieldData={user.isReadyToOffline} />
            </div>
            {
                user.isReadyToOffline &&
                <div className='profile__line'>
                    <b>Район Города: </b>
                    <UserProfileField fieldKey={"district"} fieldData={user.district} />
                </div>
            }
            <div className='profile__line' >
                <b>Часов в прошлом месяце: </b>
                <span>
                    {hoursInLastMonth && hoursInLastMonth['Итого']?.hours}
                </span>
            </div>
                <table>
                    <tbody>
                    {hoursInLastMonth && Object.entries(hoursInLastMonth).map(([keys, entries]) => (
                        <tr>
                            <td>
                                {entries.groupName}
                            </td>
                            <td>
                                {entries.hours}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
        </div>
    )
}

export default ProfilePage;