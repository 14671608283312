import { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import $api from "../service/api-service";
import { ShowMessage } from "../store/actions/toast";

const ReviewUploadBtn = ({ students, courses, teacher, groupId }) => {
    const fileInputRef = useRef()
    const dispatch = useDispatch();
    const [file, setFile] = useState();
    const [showReview, setShowReview] = useState(false);
    const [formData, setFormData] = useState({
        comment: "", student: "", group: "", courseProgress: courses[courses?.length-1]?._id
    });

    const createTask = useCallback((evt) => {
        evt.preventDefault();
        const fd = new FormData();

        if (file) {
            if (file.size > 8388608) {
                dispatch(ShowMessage("Файл слишком большой"))
                return
            }
            fd.append('file', file, file.name)
        }
        if(formData.student === "") {
            return dispatch(ShowMessage("Выбери студента", "error"))
        }

        fd.append("comment", formData.comment)
        fd.append("student", formData.student)
        fd.append("group", groupId)
        fd.append("teacher", teacher._id)
        fd.append("courseProgress", formData.courseProgress)

        $api.post('/review/create',
            fd, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(data => {
            dispatch(ShowMessage(data.data.message, 'success'));
            setFormData(p=>{return{...p, comment: "", student: "", group: ""}});
            fileInputRef.current.value = null;
            setShowReview(false);
        })
    }, [file, dispatch, formData, groupId, teacher])

    const fileSelectedHandler = useCallback(evt => {
        setFile(evt.target.files[0])
    }, [])

    const inputChangeHandler = useCallback(({ target }) => {
        setFormData(p => { return { ...p, [target.name]: target.value } })
    }, [])

    const showReviewHandler = () => {
        setShowReview(p=>!p)
    }

    return (
        <>
        <button className="review__open-btn" onClick={showReviewHandler}>Написать отзыв</button>
        {showReview &&
        <div className="review__wrap">
            <button className="group-hours__close-btn" onClick={showReviewHandler}>x</button>
            <h3>Отзыв на студента</h3>
            <form onSubmit={createTask}>
                <textarea rows={4} name="comment" value={formData.comment} onChange={inputChangeHandler}></textarea>
                <h4>Добавить тест</h4>
                <input ref={fileInputRef} title="Выбрать отзыв" onChange={fileSelectedHandler} type="file" />
                <select value={formData.student} name="student" onChange={inputChangeHandler}>
                    <option value="">Студент не выбран</option>
                    {students.map(el => {
                        return <option key={el._id} value={el._id}>{el.name}</option>
                    })}
                </select>
                <div>
                    <b>Выбери курс </b>
                    <select value={formData.courseProgress} name="courseProgress" onChange={inputChangeHandler}>
                        {courses.map(el => {
                            return <option key={el._id} value={el._id}>{el.courseName}</option>
                        })}
                    </select>
                </div>

                <button className="mgmnt__btn mgmnt__btn--review" type="submit">Отправить</button>
            </form>
        </div>
        }
        </>
    )
}

export default ReviewUploadBtn;