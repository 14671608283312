import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import $api from "../service/api-service";

const LessonsDate = ({lessonData, dateType="dd.MM"}) => {
    const [date, setDate] = useState(new Date(lessonData.date))

    const dateChangeHandler = (id, date) => {
        $api.patch('/lesson/chage-date/'+id, {date: date});
        setDate(date)
    }
    
    return (
        <ReactDatePicker
            className='lesson-item__date' 
            style={{fontSize: "24px"}}
            selected={date} 
            onChange={(date) => {dateChangeHandler(lessonData._id, date)}}
            dateFormat={dateType}
        />
    )
}

export default LessonsDate;