import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import AttandanceSelect from "../../components/attandanceSelect";
import GroupNameComponent from "../../components/groupName";
import LessonHoursComponent from "../../components/lessonHoursComponent";
import LessonsCheckComponent from "../../components/lessonsCheckComponent";
import CourseOfLesson from "../../components/courseOfLesson";
import StudentsList from "../archivePage/include/studentsListComponent";
import { FetchFullGroup, FetchOneTimers } from "../../store/actions/groupInfo";
import ManualChanger from "../../components/manualChanger";
import LessonDelComponent from "../../components/lessonDelComponent";
import ProgressName from "../../components/progressName";
import $api, { FetchCreateProgress } from "../../service/api-service";
import HoursInfoNotes from "../../components/hoursInfoNotes";
import LessonCreation from "../../components/lessonCreation";
import { GetTeacherSelectList } from "../../store/actions/teacherSelect";
import TeacherSelectView from "../../components/teacherSelectView";
import LessonWithChange from "../../components/lessonWithChange";
import ActualGroupCourse from "../../components/actualGroupCourse";
import LessonsLength from "./include/lessonsLength";
import LessonsDate from "../../components/lessonsDate";
import AddToGroup from "../../components/addToGroupComponent";
import Price from "../../components/price";
import ScheduleCreator from "./include/scheduleCreator";
import ScheduleList from "./include/scheduleList";
import GroupSpecialSalary from "./include/groupSpecialSalary";
import TeacherChange from "../../components/teacherChange";
import ArchiveGroupBtn from "../../components/archiveGroupBtn";
import UnArchiveBtn from "../../components/unArchiveBtn";

const HourInfo = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { _id, lessons, name, progress, students, isLoading, teacher, oneTimers, lessonsLength, isActive } = useSelector(state => state.groupInfo);
    const { teachersList } = useSelector(state => state.teacherSelect)
    const isManager = true;

    const updateOneTimers = useCallback(()=>{
        dispatch(FetchOneTimers(location.search.slice(1)));
    },[dispatch, location.search])

    const updateFunction = useCallback(() => {
        dispatch(GetTeacherSelectList());
        dispatch(FetchFullGroup(location.search.slice(1)));
    },[dispatch,location.search]);
    
    useEffect(()=>{
        updateFunction();
    },[updateFunction]);

    const CreateNewProgressHandler = useCallback(() => {
        FetchCreateProgress(_id)
            .then(()=>updateFunction());
    },[_id, updateFunction])

    const changeTeacherHandler = useCallback((evt, lessonId) => {
        $api.patch('/lesson/change-teacher/'+lessonId, 
            {newTeacher: evt.target.value});
    },[]);

    const deleteProgressHandler = useCallback((progressId) => {
        $api.delete('/progress/delete/'+progressId)
            .then(()=>updateFunction());
    },[updateFunction]);

    return (
        <main className="container container--responsive hour-info__main">
            <div>
                <div className="group-name__wrap">
                    {!isLoading && <GroupNameComponent groupID={_id} groupName={name} />}
                </div>
                {_id && 
                <div className="schedule">
                    <ScheduleList groupId={_id}/>
                    <div>
                        <ScheduleCreator groupId={_id} />
                    </div>
                </div>}
                    {!isLoading && <GroupSpecialSalary groupId={_id} />}
                    {!isLoading &&  
                        isActive ? 
                        <ArchiveGroupBtn groupID={_id} /> :
                        <UnArchiveBtn updateFunc={updateFunction} groupID={_id}/>
                    } 
                        
                    <ul className="group-info__progress-list">
                        {!isLoading && progress?.map(el=>{
                            const hourSum = el.autoHours + el.manualHours;
                            return (
                                <li key={el._id}>
                                    <ProgressName id={el._id} name={el.courseName} />: 
                                    <span className="group-info__hour-sum">{hourSum}</span> часов
                                    в том числе ручные: <ManualChanger updateFunc={updateFunction} id={el._id} hours={el.manualHours}/> 
                                    <button className="group-info__progress-del-btn hour-info__del-btn" onClick={()=>deleteProgressHandler(el._id)}>Удалить</button>
                                </li>)
                        })}
                    </ul>
                    <button onClick={CreateNewProgressHandler}>Добавить новый курс</button>
                    <div className="notes">
                        {!isLoading && <HoursInfoNotes />}
                    </div>
                    {!isLoading && <LessonsLength lessonsLength={lessonsLength} groupId={_id}/>}
                    {!isLoading && <TeacherChange groupID={_id} groupTeacher={teacher} updateFunction={updateFunction}/>}
                    <div className="row">
                        {!isLoading && oneTimers.map(el=><LessonWithChange updateFunc={updateOneTimers} key={el._id} lessonData={el}/>)}  
                    </div>
                    <div className="group-info__header">
                        <div className="info-header__date">
                            <b>Создано</b>
                        </div>
                        <div className="info-header__date">
                            <b>Дата</b>
                        </div>
                        <div>
                            <div className="price__list">
                                {students && students.map(el=>(
                                    <Price updateFn={updateFunction} key={"price_"+el._id} groupId={_id} userId={el._id}/>
                                ))}
                            </div>
                            <div>

                            </div>
                            {students && <StudentsList groupView={true} isManager={true} updateFunc={updateFunction} groupID={_id} studnets={students} />}
                            <div className="group-info__actual-course">
                                {!isLoading && <ActualGroupCourse groupId={_id} students={students}/>}
                            </div>
                        </div>
                        {!isLoading && <AddToGroup groupID={_id} updateFunc={updateFunction}/>}
                    </div>
                    
                    <div className="group-info__date-column">
                        <LessonCreation lastHour={lessons.at(-1)?.hours} updateFunction={updateFunction} groupData={{_id: _id, teacher: teacher}} />
                        {lessons.map(lessonData=>{
                            const lessonsCreation = lessonData.creationDate?.slice(2, 10).split("-").reverse().join('.');
                            return (
                                <div className="data-info__row" key={lessonData._id}>
                                    <div  className="lesson-item__date lesson-item__date--creation">
                                    { lessonsCreation !== "20.04.22" ? lessonsCreation: "" }
                                    </div>
                                    <div className="lesson-item__date">
                                        <LessonsDate dateType={'dd.MM.yy'} lessonData={lessonData} />
                                    </div>
                                    <AttandanceSelect groupsView={false} students={students} lesson={lessonData} updateFunction={updateFunction}/>
                                    <LessonHoursComponent 
                                        isChecked={lessonData.isChecked} 
                                        hours={lessonData.hours} 
                                        lessonID={lessonData._id}/>
                                    <LessonsCheckComponent 
                                        updateFunction={updateFunction} 
                                        isManager={isManager} 
                                        isChecked={lessonData.isChecked} 
                                        lessonID={lessonData._id}/>
                                    <CourseOfLesson 
                                        courseProgress={progress} 
                                        lessonId={lessonData._id}/>
                                    <div className="data-info__teacher-name">
                                        {lessonData.teacher?._id && 
                                            <TeacherSelectView
                                                teachersData={teachersList}
                                                defaultTeacherID={lessonData.teacher?._id}
                                                changeHandler={(evt)=>{changeTeacherHandler(evt, lessonData._id)}}/>
                                        }
                                    </div>
                                    <div>
                                        {!isLoading && <LessonDelComponent isChecked={lessonData.isChecked} updateFunction={updateFunction} lessonId={lessonData._id} />}
                                    </div>
                                </div>
                            )})}
                        </div>
                </div>

            </main>
        )
    }


export default HourInfo;
