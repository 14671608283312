const defaultState = {
    isLoading: false,
    name : "",
    email: "",
    tel: "",
    mldConnectId: "",
    childs: [],
    canPayByCard: "",
    showBalance: "",
}

export const parentReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_PARENT_DATA":
            return {
                ...state, ...action.payload, mldConnectId: action.payload?.mldConnectId
            }
        case "SET_PARENT_LOADING":
            return {
                ...state, isLoading: action.payload
            }

        default:
            return state
    }
}

export const parentLoadingSelector = state => state.parent.isLoading