import { format } from 'date-fns';

const dateConverter = (date) => {
    return date?.slice(5, 10).split("-").reverse().join('.')
}

export const beautifulDate = (date) => {
    if(!date) return
    return format(date, 'MM.dd.yy, HH:mm')
}

export default dateConverter;