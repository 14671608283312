const Switch = ({value, onChageFn, name}) => {

    return(
        <label className="switch">
            <input checked={value} onChange={onChageFn} type="checkbox" name={name} />
            <span className="slider round"></span>
        </label>
    )
}

export default Switch;