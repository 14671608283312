import React, { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch.js';

const TeacherSelect = ({defaultTeacherID="" ,changeHandler=()=>{}}) => {
    const [teachers, setTeachers] = useState('');
    const [{response}, doFetch] = useFetch('/user/teacher/all');
    const [teacherValue, setTeacherValue] = useState(defaultTeacherID)

    useEffect(() =>{
        doFetch()
    },[doFetch])

    const selectChangeHandler = (evt) => {
        setTeacherValue(evt.target.value);
        changeHandler(evt);
    }

    useEffect(()=>{
        if(!response){
            return
        }

        setTeachers(<>
            {response.sort().map(el=>{
                    return <option key={el._id} value={el._id}>{el.userName}</option>
                })}
            </>)
    }, [response])

    return(
        <select className="teachers-select" value={teacherValue}  name="teacherId" onChange={selectChangeHandler}>
            <option value="">Не выбран</option>
            {teachers}
        </select>
    )
}

export default TeacherSelect;