import DeleteOneTimeBtn from "./deleteOneTimeBtn";
import OneTimeCheckbox from "./oneTimeCheckbox";

const LessonWithChange = ({lessonData, updateFunc=()=>{}}) => {

    if (!lessonData) {
        return <div></div>
    }

    return (
        <div className="onetime-list__item">
            <div className="one-time__delete-btn">
                {/* {!lessonData.isChecked &&  */}
                    <DeleteOneTimeBtn updateFunc={updateFunc} onetimeID={lessonData._id}/>
                {/* } */}
            </div>
            <div>{lessonData.date.slice(5, 10).split("-").reverse().join('.')}</div>
            <div>{lessonData.lessonType === "test" ? "Пробное" : "Замена"}</div>
            <div className="onetime-list__group-name">{lessonData.teacherName}</div>
            <div><i>{lessonData.comment}</i></div>
            <div>
                <i>{lessonData.hours}</i>
            </div>
            <OneTimeCheckbox oneTimeID={lessonData._id} isChecked={lessonData.isChecked}/>
        </div>
    )
}

export default LessonWithChange;