import { useCallback, useEffect, useState } from 'react';
import $api from '../service/api-service';
import convertSum from '../service/sumConverter';

const Payroll = () => {
    const [payroll, setPayroll] = useState({
        payrollList: [],

    })

    const fetchPayroll = useCallback(()=>{
        $api.get('/payroll/bymonth/07/2024')
        .then(({data})=>setPayroll(data))
    },[])

    useEffect(()=> {
        fetchPayroll()
    },[fetchPayroll])


    const changeEmployStatus = ({target}) => {
        $api.patch('/user/current-user', {
            field: 'employStatus',
            value: target.value,
            userId: target.dataset.teacher
        })
    }

    const toCopy = true;

    return(
        <div className='container'>
            <h1>Платежная ведомость</h1>
            <table>
                <thead>
                    <tr>
                        <td>
                            Имя
                        </td>
                        <td>
                            Отчество
                        </td>
                        <td>
                            Статус
                        </td>
                        <td>
                            Сумма
                        </td>
                        <td>
                            Налог
                        </td>
                        <td>
                            Доплата
                        </td>
                        <td>
                            Итог
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {payroll.payrollList?.map(el=>(
                        <tr key={el._id}>
                            <td>
                                {el.name}
                            </td>
                            <td>
                                {el.teacherId.patronymic}
                            </td>
                            <td>
                                <select data-teacher={el.teacherId._id} defaultValue={el.teacherId.employStatus} onChange={changeEmployStatus}>
                                    <option value="MNG">УПР</option>
                                    <option value="SELF">Самозанятый</option>
                                    <option value="IE">ИП</option>
                                </select>
                            </td>
                            <td>
                                {convertSum(el.totalSalary)}
                            </td>
                            <td>
                                {toCopy ? 
                                    el.tax :
                                    <PayrollTax updateFunc={fetchPayroll} tax={el.tax} payrollId={payroll._id} payrollListItemId={el._id}/>
                            }
                            </td>
                            <td>
                                {toCopy ?
                                    el.surcharge :
                                    <PayrollSurcharge updateFunc={fetchPayroll} surcharge={el.surcharge} payrollId={payroll._id} payrollListItemId={el._id}/>
                                }
                            </td>
                            <td>
                                {convertSum(el.sumToPay)}
                            </td>
                            {/* <td>
                                {el.offlineIndividualHours}
                            </td>
                            <td>
                                {el.offlineGroupHours}
                            </td>
                            <td>
                                {el.onOffHours}
                            </td> */}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const PayrollSurcharge = ({payrollId, payrollListItemId, surcharge, updateFunc}) => {
    const [surchargeValue, setSurchargeValue] = useState(surcharge)
    const valueChangeHandler = ({target}) => {
        setSurchargeValue(target.value)
    }

    const surchargeHander = (evt) => {
        evt.preventDefault()
        $api.post('/payroll/surcharge', {
            payrollId, 
            payrollListItemId, 
            newSurcharge: surchargeValue
        }).then(()=>{
            updateFunc();
        })
    }

    return (
        <div>
            <form onSubmit={surchargeHander} >
                <input type='number' value={surchargeValue} onChange={valueChangeHandler} />
            </form>
        </div>
    )
}

const PayrollTax = ({payrollId, payrollListItemId, tax, updateFunc}) => {
    const [taxValue, setTaxValue] = useState(tax)
    const valueChangeHandler = ({target}) => {
        setTaxValue(target.value)
    }

    const taxHander = (evt) => {
        evt.preventDefault()
        $api.post('/payroll/surcharge', {
            payrollId, 
            payrollListItemId, 
            newTax: taxValue
        }).then(()=>{
            updateFunc();
        })
    }

    return (
        <div>
            <form onSubmit={taxHander} >
                <input type='number' value={taxValue} onChange={valueChangeHandler} />
            </form>
        </div>
    )
}

export default Payroll;