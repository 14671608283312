import { useEffect, useState } from "react";
import useFetch from '../../hooks/useFetch';
import { Link } from "react-router-dom";
import converSum from '../../service/sumConverter';

const BalancePage = () => {
    const [incomes, setIncomes] = useState([]);
    const [{response}, doFetch] = useFetch('/student/allbalance');

    useEffect(()=>{
        doFetch()
    },[doFetch])

    useEffect(()=>{
        if(!response) {
            return
        }
        setIncomes(response)
    },[response])

    return (
        <main className="container">
            <h1>Балансы</h1>
            <table>
            <tbody>            
                {incomes.map(el=>{
                    return (
                        <tr key={el.userId}>
                            <td>
                                <Link to={"/archive/"+el.userId}>
                                    {el.userName}
                                
                                </Link>
                            </td>
                            <td>
                                {el.contract}
                            </td>
                            <td>
                                {converSum(el.sumBalance)}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </main>
    )
}

export default BalancePage;