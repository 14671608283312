import { useState } from "react";
import $api from "../service/api-service";

const ManualChanger = ({hours, id, updateFunc}) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState(hours)

    const clickHandler = () => {
        setShowInput(p=>!p);
    }

    const inputChangeHandler = ({target}) => {
        setInputValue(target.value);
    }

    const submitHandler = (evt) => {
        evt.preventDefault();
        $api.post('/progress/update/manual/'+id,
            {manualHours: inputValue}).then(()=>{
                updateFunc();
                setShowInput(false);
            });
    }

    return (
        <div className="hour-info__manual">
            {showInput ? 
            <form onSubmit={submitHandler}>
                <input value={inputValue} onChange={inputChangeHandler} type="text" inputMode="numeric" pattern="\d*" />
                <button type="submit">Сохранить</button>
            </form>
            :
                <b onClick={clickHandler}>
                    {hours}
                </b>
            }
        </div>
        
    )
}

export default ManualChanger;