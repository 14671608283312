import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChangeLessons from '../components/changeLessons.js';
import GroupList from '../components/groupList.js';
import Loading from '../components/loading.js';
import OneTime from '../components/oneTime.js';
import createCurMount from '../service/monthCreator.js';
import { FetchLessons } from '../store/actions/lessons.js';
import TeacherSchedule from '../components/teacherSchedule.js';

const LessonsPage = () => {
    const dispatch = useDispatch();
    const currentUserState = useSelector(state => state.currentUser);
    const lessonsState = useSelector(state =>  state.lessons);
    
    const mounthChange = useCallback(({target}) => {
        dispatch({type: "SET_MONTH", payload: target.value})
    },[dispatch])

    const yearChange = useCallback(({target}) => {
        dispatch({type: "SET_YEAR", payload: target.value})
    },[dispatch])

    useEffect(()=>{
        dispatch(FetchLessons(lessonsState.month, currentUserState?.currentUser?.userID, lessonsState.year, "all"))
    },[lessonsState.month, lessonsState.year, currentUserState?.currentUser?.userID, dispatch])
    
    return(
        <div className="container lessons-page__wrap">
            <h1>Ведомость занятий</h1>
            <TeacherSchedule teacherId={currentUserState?.currentUser?.userID}/>
            <div className='lessons-settings__wrap'>
                <div>
                    <p className='lesson-mgmnt__label' >Месяц</p>
                    <select className="lesson-mgmnt__mounth" onChange={mounthChange} defaultValue={createCurMount()}>
                        <option value="01">Январь</option>
                        <option value="02">Февраль</option>
                        <option value="03">Март</option>
                        <option value="04">Апрель</option>
                        <option value="05">Май</option>
                        <option value="06">Июнь</option>
                        <option value="07">Июль</option>
                        <option value="08">Август</option>
                        <option value="09">Сентябрь</option>
                        <option value="10">Октябрь</option>
                        <option value="11">Ноябрь</option>
                        <option value="12">Декабрь</option>
                    </select>
                </div>
                <div>
                    <p className='lesson-mgmnt__label'>Год</p>
                    <input type='number' onChange={yearChange} name="year" className="lesson-mgmnt__year" defaultValue={lessonsState.year} min="2020" max="2050" step="1"/>
                </div>
            </div>
            <OneTime currentMounth={lessonsState.month} teacherID={currentUserState.currentUser !== null ? currentUserState.currentUser.userID: ""}/>
            <ChangeLessons userId={currentUserState?.currentUser?.userID} />
            {lessonsState.isLoading && <Loading />}
            <GroupList lessonsArr={lessonsState.lessonsList}/>
        </div>
    )
}

export default LessonsPage;
