import $api from "../service/api-service";
import { translateDayOfWeek } from "../utils/dayOfWeekUtils";
import { useState } from "react";

const GroupSchedule = ({updateFunc, schedule}) => {

    return(
        <div>
            <ul className="group-elem__schedule-list"> 
                    {schedule?.days?.map(el => (
                        <li className="schedule__item" key={el._id}>
                            <DayOfWeekElem updateFunc={updateFunc} schedule={el} scheduleId={schedule._id} />                        
                        </li>
                    ))}
                </ul>
        </div>)
    }

const DayOfWeekElem = ({schedule, scheduleId, updateFunc}) => {
    const [isCorrect, setIsCorrect] = useState(false);

    const [formData, setFormData] = useState({
        startTime: schedule.time.startTime || '11:00',
        endTime: schedule.time.endTime|| '12:00',
        dayOfWeek: schedule.dayOfWeek || 'Monday', // Значение по умолчанию
      });

    const clickHandler = () => {
        setIsCorrect(true)
    }

    const delClickHandler = ({ target }) => {
        const dataId = target.getAttribute('dataid');
        $api.patch('/schedule/delete-day/' + scheduleId, {
            day: dataId
        }).then(()=>updateFunc())
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const newScheduleHandler = (e) => {
        e.preventDefault();
        
        $api.patch('/schedule/update-current/'+scheduleId,
        {
            updatedDay: formData,
            dayId: schedule._id
        }).then(()=>{
            updateFunc();
            setIsCorrect(false)
        })
      };

    const cancelClickHander = () => {
        setIsCorrect(false)
    }

    return (
    <div>
        {isCorrect ? 
        <form className="schedule__creating-form" onSubmit={newScheduleHandler}>
            <select
                id="dayOfWeek"
                name="dayOfWeek"
                value={formData.dayOfWeek}
                onChange={handleChange}
            >
                <option value="Monday">Понедельник</option>
                <option value="Tuesday">Вторник</option>
                <option value="Wednesday">Среда</option>
                <option value="Thursday">Четверг</option>
                <option value="Friday">Пятница</option>
                <option value="Saturday">Суббота</option>
                <option value="Sunday">Воскресенье</option>
            </select>
            <input
                type="time"
                name="startTime"
                placeholder=""
                value={formData.startTime}
                onChange={handleChange}
            />
            <input
                type="time"
                name="endTime"
                placeholder=""
                value={formData.endTime}
                onChange={handleChange}
            />
            <button type="submit">Создать</button>
            <button onClick={cancelClickHander}>x</button>
        </form>
        :
        <div className="group-elem__schedule-item">
            <div className="group-elem__schedule-content" onClick={clickHandler}>
                <span className="schedule-item__weekday">
                    {translateDayOfWeek(schedule.dayOfWeek)}
                </span>
                <span className="schedule-item__time">
                    {schedule.time.startTime} — {schedule.time.endTime}
                </span>
            </div>
            <button className="group-elem__schedule-del-btn" dataid={schedule._id} onClick={delClickHandler}>
                x
            </button>
        </div>
        }
    </div>)
}

export default GroupSchedule;