import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetFilesList, uploadFile } from "../../store/actions/disk";
import { setCurrentDir, setPopupDisplay } from "../../store/disk";
import Popup from "./include/popup";
import './disk.css'
import FileList from "./include/fileList";
import Uploader from "./include/uploader";
import { isAdminSelector } from "../../store/currentUser";

const DiskPage = () => {
    const dispatch = useDispatch();
    const currentDir = useSelector(state => state.files.currentDir);
    const dirStack = useSelector(state => state.files.dirStack);
    const isAdmin = useSelector(isAdminSelector);
    const [dragEnter, setDragEnter] = useState(false)
    const [sort, setSort] = useState('name');

    useEffect(()=>{
        dispatch(GetFilesList(currentDir, sort));
    },[dispatch, currentDir, sort])

    function showPopupHandler() {
        dispatch(setPopupDisplay('flex'))
    }

    function backClickHandler() {
        const backDirId = dirStack.pop()
        dispatch(setCurrentDir(backDirId))
    }

    function fileUploadHandler(event) {
        const files = [...event.target.files]
        files.forEach(file => dispatch(uploadFile(file, currentDir)))
    }

    function dragEnterHandler(event) {
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(true)
    }

    function dragLeaveHandler(event) {
        event.preventDefault()
        event.stopPropagation()
        setDragEnter(false)
    }

    function dropHandler(event) {
        event.preventDefault()
        event.stopPropagation()
        let files = [...event.dataTransfer.files]
        files.forEach(file => dispatch(uploadFile(file, currentDir)))
        setDragEnter(false)
    }

    if(dragEnter && isAdmin) {
        return (
            <div className="drop-area" onDrop={dropHandler} onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler}>
                Перетащите файлы сюда
            </div>
        )
    }

    return (<div className="container">
            <div className="disk" onDragEnter={dragEnterHandler} onDragLeave={dragLeaveHandler} onDragOver={dragEnterHandler}>
            <h1>Страница с методичками</h1>
            <div className="disk__btns">
                <button className="disk__back" onClick={() => backClickHandler()}>Назад</button>
                {isAdmin &&
                    <>
                        <button className="disk__create" onClick={() => showPopupHandler()}>Создать папку</button>
                        <div className="disk__upload">
                            <label htmlFor="disk__upload-input" className="disk__upload-label">Загрузить файл</label>
                            <input multiple={true} onChange={(event)=> fileUploadHandler(event)} type="file" id="disk__upload-input" className="disk__upload-input"/>
                        </div>
                    </>
                }
                <select value={sort}
                        onChange={(e) => setSort(e.target.value)}
                        className='disk__select'>
                    <option value="name">По имени</option>
                    <option value="type">По типу</option>
                    <option value="date">По дате</option>
                </select>
            </div>
                <FileList/>
                <Popup/>
                <Uploader/>
            </div>
        </div>)
}

export default DiskPage;