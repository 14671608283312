import { FetchCreateProgress } from "../service/api-service";
import GroupHours from "./groupHours";
import { Link } from 'react-router-dom';

const GroupHoursList = ({isManager, progress, updateFunc, groupID}) => {

    const btnClickHandler = () => {
        if(progress) {
            FetchCreateProgress(groupID).then(()=>{
                updateFunc();
            });
        }
    }

    return (
    <>
        { isManager ?
            <>
            {progress.map(el=>{
                    return <GroupHours showDel={progress.length > 1} updateFunc={updateFunc} key={el._id} progress={el}/> 
                })}
                <Link className="hour-info__link" to={"/hour-info/?"+groupID}>
                    i
                </Link>
            <button onClick={btnClickHandler}>+</button>   
            </>
           :
            <div className="hours--for-teacher">{progress.map(el=>{
                return <div className="group-hours" title={el.courseName} key={el._id}>
                    {el.autoHours + el.manualHours}
                </div>
            })}</div>
        }
        
    </>)
}

export default GroupHoursList;