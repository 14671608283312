import React from "react";
import { useSelector } from "react-redux";
import GroupArchive from "./include/groupArchive";
import StudentCard from "./include/studentCard";
import StudentToDelete from "./include/studentToDelete";
import { Link, Route } from 'react-router-dom'
import ParentCard from "./include/parentCard";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import BalanceCard from "./include/balanceCard";


const ArchivePage = () => {
    const studentState = useSelector(state => state.student);
    const userId = useSelector(state => state.currentUser.currentUser.userID);
    const access = userId === '61093360789de99cc30959b5' || userId === '654b563d01de6b56a5a68493'

    return(
        <div className="container">
            <h1>Страница управления</h1>
            <div className="manage-page__links">
                {access && <NavLink to="/anltcs/attendances">🧮 Оборот</NavLink>}
                {access && <NavLink to="/manual-money">💸 Ручные зачисления</NavLink>}
            </div>
            <div className="manage-page__links">
                <NavLink to="/incomes">Платежи</NavLink>
                <NavLink to="/anltcs">Сумма часов по преподам</NavLink>
                <NavLink to="/anltcs/reviews">Рейтинг по отзывам</NavLink>
                <NavLink to="/balances">Балансы</NavLink>
            </div>
            <div className="archive-page__wrap">
                <GroupArchive />
                <StudentToDelete />
                <Route path={'/archive/*'}>
                    <div className="student-card__wrap">
                        <Link className="student-card__close-btn" to={'/archive'}>х</Link>
                        <Route path={"/archive/:userId"} exact>
                            <StudentCard {...studentState} />
                        </Route>
                        <Route path={"/archive/parent/:parentId"}>
                            <ParentCard />
                        </Route>
                        <Route path={"/archive/balance/:studentId"}>
                            <BalanceCard />
                        </Route>
                    </div>
                </Route>
            </div>
        </div>
    )
}

export default ArchivePage;