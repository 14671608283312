import { useEffect, useState } from "react";
import $api from "../../../service/api-service";

const ReviewCounter = () => {
    const [teachersData, setTeacherData] = useState([]);

    useEffect(()=>{
        $api.get('/review/analytics')
            .then(response => setTeacherData(response.data));
    },[])
    
    return(
        <table>
            <thead>
                <tr>
                    <th>Преподаватель</th>
                    <th>Отз</th>
                </tr>
            </thead>
            <tbody>
                {teachersData.map(el=>{
                    return (
                        <tr key={el.userName+"reviews"}>
                            <td>
                                {el.userName}
                            </td>
                            <td>
                                {el.reviews}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default ReviewCounter;