import $api from "../../service/api-service"
import { ShowMessage } from "./toast"

export const sendNotification = (studentId) => {
    return dispatch => {
        dispatch({type: 'SET_PAYMENT_LOADING', payload: true})
        $api.post(`/student/send-payment-notification/${studentId}`)
            .then(response=>{
                if(response.status === 200) {
                    const {data} = response
                    dispatch({type: "SET_LAST_NOTIFICATION", payload: {
                        lastNotificationDate: data.createdAt,
                        lastNotificationId: data._id
                    }})
                }
            }).catch(err=>{
                dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: 'SET_PAYMENT_LOADING', payload: false})
            })
    }
}