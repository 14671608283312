const defaultState = {
    message: "",
    emotional: "neutral"
}

export const toastReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_MESSAGE":
            return {...state, message: action.payload.content, emotional: action.payload.emotional || "neutral"}
        case "CLEAR_MESSAGE":
            return {...state, message: "", emotional: "neutral"}

        default:
            return state
    }
}

export const ToastActionCreator = (message, emotional) =>{
    return {type: "SET_MESSAGE", payload: {content: message, emotional: emotional}}
}