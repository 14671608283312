import React, { useEffect, useState } from 'react';
import useFetch from '../hooks/useFetch.js';
import pen from '../img/jam_write.png';

import DatePicker,{registerLocale,setDefaultLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { ru } from "date-fns/locale/ru";
registerLocale('ru',ru);
setDefaultLocale('ru');

const LessonCreation = ({groupData, updateFunction=()=>{}, lastHour = 1}) =>{
    const [btnDisablet, setBtnDisbled] = useState(false);
    const [curDate, setCurDate] = useState(new Date());
    const [{response}, doFetch] = useFetch('/lesson/create')

    const [lessonDate, setLessonDate] = useState(new Date().toJSON().slice(0,10))

    const changeDateHandler = (date) => {
        if(date !== ""){
            setBtnDisbled(false)
        } else {
            setBtnDisbled(true)
        }
        setCurDate(date)
        setLessonDate(date)
    }

    const addLessonHandler = () => {
        doFetch({
            method: 'post',
            data: {
                groupId: groupData._id,
                date: lessonDate,
                hours: lastHour,
                teacherID: groupData.teacher._id
            }
        })
    }

    useEffect(()=>{
        if(!response){
            return
        }
        updateFunction()
    },[response, updateFunction])
    
    return(
        <div className="lesson-creation__wrap">
            <div className={"lesson-creation__date-input"} >
                <DatePicker
                    className='lesson-item__date' 
                    style={{fontSize: "24px"}}
                    selected={curDate} 
                    onChange={(date) => {changeDateHandler(date)}}
                    dateFormat="dd.MM"
                    locale="ru"
                />
            </div>
            <button className="lesson-create__btn" onClick={addLessonHandler} disabled={btnDisablet}>
                <img src={pen} alt="создать"/>
            </button>
        </div>
    )
}

export default LessonCreation;