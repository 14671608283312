import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { GetStudentsList } from "../../../store/actions/students.js";

function escapeRegExp(str) {

    return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
}

const StudentToDelete = () => {
    const dispatch = useDispatch();
    const studentListState = useSelector(state => state.student.studentsList);
    const [search, setSearch] = useState("");
    const [hide, setHide] = useState(false);
    const [showActive, setShowActive] = useState(false);

    useEffect(()=>{
        dispatch(GetStudentsList());
    },[dispatch])

    const searchChangeHandler = ({target}) => {
        setSearch(target.value);
    }

    const hideBtnClickHandler = () => {
        setHide(p=>!p);
    }

    const showNotActive = (evt) => {
        setShowActive(p=>!p)
    }
    return(
        <div>
            <div className="student-list__header">
                <h3>Список студентов</h3>
                <label>
                    Арх
                    <input onChange={showNotActive} checked={showActive} type="checkbox"/>
                </label>
                <button onClick={hideBtnClickHandler}>
                    {hide ? 
                    "Показать"
                    :    
                    "Свернуть"
                    }
                </button>
            </div>
            {!hide &&
            <>
                <input type="text" placeholder="Поиск" className="student-list__search-field" value={search} onChange={searchChangeHandler} />
                <ul>
                { studentListState.filter(el=>(new RegExp(escapeRegExp(search), 'gi').test(el.name) && (el.isActive === !showActive)) ).map(el=>{
                    return(
                        <li key={el._id} >
                            <NavLink 
                                to={'/archive/'+el._id} 
                                activeClassName="group-list__item group-list__item--selected" 
                                className={"group-list__item"}>
                                <span className="group-item__name">{el.name}</span>
                                {el.parent?.mldConnectId && <div className="parent__active-cab"></div>}
                                {el.parent?.showBalance && <div className="parent__active-balance"></div>}
                            </NavLink>
                    </li>)
                }) }
                </ul>
            </>
            }
        </div>
    )
}

export default StudentToDelete;