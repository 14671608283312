import { useDispatch } from "react-redux"
import { CreatePaymentLink, CreateQrPaymentLink } from "../store/actions/students"
import { useState } from "react";

const PaymentLinkGenerator = ({contract, email}) => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState(1000);

    const createPaymenthandler = () => {
        dispatch(CreatePaymentLink(contract, amount * 100, email))
    }

    const createQrPaymenthandler = () => {
        dispatch(CreateQrPaymentLink(contract, amount * 100, email))
    }

    const amountChangeHandler = ({target}) => {
        setAmount(target.value);
    }

    return (
        <div className="field-line">
            <input className="student-card__field-tag" type="number" value={amount} onChange={amountChangeHandler} />
            <button onClick={createPaymenthandler} className='student-card__notify-btn'>Создать ссылку для оплаты</button>
            <button onClick={createQrPaymenthandler} className='student-card__notify-btn'>Создать qr для оплаты</button>
        </div>
)
}

export default PaymentLinkGenerator;