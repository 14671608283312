const FilialCreation = ({filialAccess, changeHandler=()=>{}}) => {
    if(filialAccess?.length <= 1) {
        return <></>
    }

    return(
        <select name='filial' onChange={changeHandler}>
            {filialAccess?.map(el=>{
                return <option value={el._id} key={el._id}>{el.name}</option>
            })}

        </select>
    )
}

export default FilialCreation;