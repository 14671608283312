import { useEffect, useState } from 'react';
import StudentSearch from './studentSearch';
import TeacherSelect from './teacherSelect';
import { useDispatch, useSelector } from 'react-redux';
import { CreateOneTime } from '../store/actions/lessons';
import $api from '../service/api-service';
import moment from 'moment'

const OneTimeCreation = ({teacherID, isManager = false}) => {
    const userRole = useSelector(state => state?.currentUser?.currentUser?.userRole);
    const dispatch = useDispatch();
    
    const [filials, setFilials] = useState([]);

    const [formData, setFormData] = useState({
        date: moment(new Date()).format('YYYY-MM-DD'),
        oneTimeType: "test",
        groupID: "",
        comment: "",
        hours: 0.75,
        teacherID: teacherID,
        filial: userRole?.filialAccess[0]._id || "63c63702397ca6783eb57fa2"
    })

    useEffect(()=>{
        let isCancelled = false;
        $api.get('/filial/')
            .then(response => {
                if(!isCancelled) setFilials(response.data);
            })

        return () => {
            isCancelled = true;
        };
    },[])

    

    const changeHandler = ({target}) => {
        setFormData({...formData, [target.name]: target.value})
    }

    const teacherChangeHandler = ({target}) => {
        setFormData({...formData, teacherID: target.value})
    }

    const createClickHandler = () => {
        dispatch(CreateOneTime({
            teacherID: formData.teacherID,
            groupID: formData.oneTimeType === "test"? "": formData.groupID,
            lessonType: formData.oneTimeType,
            date: formData.date,
            comment: formData.comment,
            hours: formData.hours,
            filial: formData.filial
        }))
    
        setFormData(p=> ({
            ...p,
            date: moment(new Date()).format('YYYY-MM-DD'),
            oneTimeType: "test",
            groupID: "",
            comment: "",
            hours: 0.75,
        }))
    }

    return(
        <div className="onetime__creation-wrap">
            {isManager && <TeacherSelect changeHandler={teacherChangeHandler}/>}
            <div>
                <input autoComplete='off' value={formData.date} onChange={changeHandler} type="date" name="date" />
            </div>
            <div>
                <select value={formData.oneTimeType} onChange={changeHandler} name="oneTimeType">
                    <option value="test">Пробное</option>
                    <option value="change">Замена</option>
                </select>
            </div>
            <div>
                {formData.oneTimeType !== "test" && <StudentSearch chandgeDataFunc={changeHandler}/>}
            </div>
            {formData.oneTimeType === "test" &&
                <div>
                    <select 
                        value={formData.filial} 
                        onChange={changeHandler} 
                        name="filial">
                        {filials.map(el=>{
                            return <option key={el._id} value={el._id}>{el.name}</option>
                        })}
                    </select>
                </div>
            }
            <div>
                <input autoComplete='off' placeholder="Комментарий/Имя студента на пробном" value={formData.comment} onChange={changeHandler} type="text" name="comment" />
            </div>
            <div>
                <input autoComplete='off' onChange={changeHandler} className="one-time__hours-input" value={formData.hours} step={0.25}  type="number" name="hours" />
            </div>
            <button onClick={createClickHandler} className="yc-button">Создать</button>
        </div>
    )
}

export default OneTimeCreation;