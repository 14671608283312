import React, { useEffect } from "react";
import useFetch from "../hooks/useFetch.js";

const UserItem = ({userName, userID, updateFunc}) => {
    const [{response}, doFetch] = useFetch('/user/'+userID)
    const deleteClickHandler = () => {
        doFetch({
            method: 'delete'
        })
    }

    useEffect(()=>{
        if(!response) {
            return
        }
    
        updateFunc();
    },[response, updateFunc])

    return(
        <div>
            {userName}
            <button onClick={deleteClickHandler}>X</button>
        </div>
    )
}

export default UserItem;