import { useEffect } from "react";
import $api from "../../../service/api-service";
import { useDispatch, useSelector } from "react-redux";
import { scheduleSelector } from "../../../store/groupInfo";
import { translateDayOfWeek } from "../../../utils/dayOfWeekUtils";

const ScheduleList = ({groupId}) => {
    const schedule = useSelector(scheduleSelector);
    const dispatch = useDispatch();
    
    useEffect(()=> {
        $api.get('/schedule/group/'+groupId)
        .then(({data})=>{
            dispatch({type: "SET_GROUP_SCHEDULE", payload: data});
        })
        .catch(err=>{
            console.log(err)
        })
    },[groupId, dispatch])

    const delClickHandler = ({ target }) => {
        const dataId = target.getAttribute('dataid');
        $api.patch('/schedule/delete-day/' + schedule._id, {
            day: dataId
        })
        .then(({data})=>{
            dispatch({type: "SET_GROUP_SCHEDULE", payload: data});
        })
        .catch(err=>{
            console.log(err)
        })
    }
    
    return (
        <div>
            <h3 className="schedule__headline">Расписание</h3>
            <ul className="schedule__list"> 
                {schedule?.days.map(el => (
                    <li className="schedule__item" key={el._id}>
                        <span className="schedule-item__weekday">
                            {translateDayOfWeek(el.dayOfWeek)}
                        </span>
                        <span className="schedule-item__time">
                            {el.time.startTime} — {el.time.endTime}
                        </span>
                        <button className="hour-info__del-btn" dataid={el._id} onClick={delClickHandler}>
                            Удалить
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
    
}

export default ScheduleList;