import React, { useCallback, useEffect, useState } from "react";
import useFetch from '../hooks/useFetch.js';
import AddToGroupBtn from "./addToGroupBtnComponent.js";
import { StudentSearchItem } from "./studentSearch.js";

const AddToGroup = ({groupID, updateFunc}) => {
    const [matchStudent, setMatchStudent] = useState();
    const [urlParams, setUrlParams] = useState('');
    const [addStudentID, setAddStudentID] = useState('');
    const [{response}, doFetch] = useFetch('/student/search/'+urlParams);

    const [searchShow, SetSearchShow] = useState(false)
    const addClickHandler = () => {
        SetSearchShow(!searchShow)
        setMatchStudent("")
    }

    const UpdateGroup = useCallback(() => {
        updateFunc();
        SetSearchShow(false);
        setUrlParams("");
    },[updateFunc])

    const searchChangeHandler = ({target}) => {
        if(target.value === "") {
            setMatchStudent("");
            setUrlParams("");
            return;
        }

        setUrlParams(target.value);
        doFetch();
    }

    useEffect(()=>{
        if(!response) {
            return
        }

        setMatchStudent(response)
    },[response])

    const resultClickHandler = (studentID, studentName) => {
        setUrlParams(studentName);
        setMatchStudent("")
        setAddStudentID(studentID);
    }

    const submitHandler = (evt) => {
        evt.preventDefault();
    }

    return(
        <div className="add-to-group__wrap">
            <div>
                <button className="add-student__btn" onClick={addClickHandler}>Добавить студентов</button>
            </div>
            {searchShow && <div className="student-list-add">
                <form autoComplete="off" onSubmit={submitHandler} >
                    <input placeholder="Имя студента..." value={urlParams} onChange={searchChangeHandler} type="text"/>
                    <AddToGroupBtn updateFunc={UpdateGroup} groupID={groupID} studentID={addStudentID}/>
                </form>
                <StudentSearchItem studentsArr={matchStudent} resultClickHandler={resultClickHandler}/>
            </div>}
        </div>
    )
}

export default AddToGroup;