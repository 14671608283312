import { useCallback, useEffect, useState } from "react";
import $api from "../service/api-service";
import { useDispatch } from "react-redux";
import { ShowMessage } from "../store/actions/toast";
import { Link } from "react-router-dom";
import { beautifulDate } from "../service/dateConverter";

const ManualMoneyAdd = () => {
    const [studentList, setList] = useState([]);
    const [manualPaymentsList, setManualPaymentsList] = useState([]);
    const dispatch = useDispatch();

    const [paymentData, setPaymentData] = useState({
        studentName: "",
        contract: "",
        info: "",
        amount: 0
    });

    const changeHandler = ({target}) => {
        setPaymentData(p=>({...p, [target.name]: target.value}));
    }

    const nameChangeHandeler = ({target}) => {
        setPaymentData(p=>({...p, studentName: target.value}));
        if(target.value.length > 2) {
            $api.get('/student/search/'+target.value)
                .then(({data})=>{
                    setList(data)
                })
        }
    }

    const searchResultClickHandler = ({target}) => {
        setPaymentData(p=>({...p, 
            contract: target.dataset.contract,
            studentName: target.dataset.name
        }));
        setList([])
    }

    const fetchManualsList = useCallback(() => {
        $api.get('/income/manual-payments')
        .then(({data})=>{
            setManualPaymentsList(data)
        })
        .catch((error)=>{console.log(error)})
    },[])

    const submitHandler = useCallback((evt) => {
        evt.preventDefault();
        $api.post('/income/create', {
            accesstoken: "da1231ss0604",
            contract: paymentData.contract,
            amount: paymentData.amount,
            info: paymentData.info,
            manual: true
        }).then(()=>{
            dispatch(ShowMessage("Платеж создан"));
            setPaymentData({
                studentName: "",
                contract: "",
                info: "",
                amount: 0,
            });
            fetchManualsList();
        })
    },[dispatch, fetchManualsList, paymentData.amount, paymentData.contract, paymentData.info])

    const deleteClickHandler = ({target}) => {
        $api.delete(`/income/${target.dataset.student_id}`, {
            data: {
                accesstoken: "da1231ss0604"
            }
        }).then(()=>{
            fetchManualsList()
        })
    }

    useEffect(()=>{
        fetchManualsList()
    },[fetchManualsList])

    return (
        <div className="container">
            <h1>Ручные оплаты</h1>
            <div>
                <form onSubmit={submitHandler}>
                    <div className="creating-page__input-wrap">
                        <input value={paymentData.studentName} type="text" name="studentName" placeholder="Имя студента" onChange={nameChangeHandeler}/>
                        <span>{paymentData.contract}</span>
                    </div>
                    <div className="searching-student__wrap payment--page__searching">
                        {studentList.map(el=>(
                            <div key={el._id} 
                                onClick={searchResultClickHandler} 
                                className="search-result__item"
                                data-contract={el.contract} 
                                data-name={el.name} 
                            >
                                {el.name}</div>
                        ))}
                    </div>
                    <div className="creating-page__input-wrap">
                        <input value={paymentData.amount} type="number" name="amount" placeholder="Сумма" onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <input value={paymentData.info} type="text" name="info" placeholder="Инфо" onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        {paymentData.contract && <button className="yc_button" type='submit'>Создать</button>}
                    </div>
                </form>
                <table>
                    <tbody>
                        {manualPaymentsList?.map(el=>(
                            <tr key={el._id}>
                            <td>
                                <Link to={"/archive/"+ el.balance.student._id} >
                                    {el.balance.student.name}
                                </Link>
                            </td>
                            <td>
                                {el.amount} ₽ 
                            </td>
                            <td>
                                {beautifulDate(el.date)}
                            </td>
                            <td>
                                {(el.info)}
                            </td>
                            <td>
                                <button data-student_id={el._id} onClick={deleteClickHandler}>Удалить</button>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ManualMoneyAdd;