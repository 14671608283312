import React, { useEffect, useRef } from "react";
import useFetch from '../hooks/useFetch.js';
import AlarmComponent from "./alarmComponent.js";

const LessonDelComponent = ({isChecked, lessonId, updateFunction}) => {
    const [{response}, doFetch] = useFetch('/lesson/'+lessonId);
    const alarmChild = useRef();

    const delHandler = () => {

        doFetch({
            method: 'delete'
        })
    }

    useEffect(()=>{
        if(!response){
            return
        }

        updateFunction();
    },[response, updateFunction])

    return(
        <div className="lesson-del__btn-wrap">
            {!isChecked&& <button className="lesson-item__remove-btn" onClick={()=>{alarmChild.current.alarmCall()}} >×</button>}
            <AlarmComponent ref={alarmChild} cb={delHandler} description="Вы точно хотите удалить урок? Вернуть его будет невозможно" />
        </div>
    )
}

export default LessonDelComponent;
