import { useState } from "react";
import { useSelector } from "react-redux";

const TeacherSelectView = ({teachersData, defaultTeacherID="" , changeHandler=()=>{}}) => {
    const [teacherValue, setTeacherValue] = useState(defaultTeacherID);
    const { teacherLoading } = useSelector(state=>state.teachers);
    
    const selectChangeHandler = (evt) => {
        setTeacherValue(evt.target.value);
        changeHandler(evt);
    }

    if(teacherLoading) {
        return <div>Загрузка...</div>
    }

    if(!teacherLoading) {
        return(
            <select className="teachers-select" value={teacherValue}  name="teacherId" onChange={selectChangeHandler}>
                <option value="">Не выбран</option>
                {teachersData?.map(el=><option key={el._id} value={el._id}>{el.userName}</option>)}
            </select>
        )
    }
}

export default TeacherSelectView;