import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import $api from "../service/api-service";
import { useDispatch } from "react-redux";
import { ShowMessage } from "../store/actions/toast";

const TeacherHourPage = () => {
    const {id,month} = useParams();
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    useEffect(()=>{
        try{
            $api.get(`/lesson/what-about-me/${id}/${month}/2024`)
            .then(({data})=>{
                setData(data)
            })
        }catch(e) {
            dispatch(ShowMessage('Ошибка'))
        }
    },[dispatch, id, month])

    return(
        <div className="container">
            <h1>{data && data.name}</h1>
            <table>
                <tbody>
                        {data && Object.entries(data).map(([keys, entries]) => (
                            <tr key={keys}>
                                <td>
                                    {entries.groupId ? 
                                        <NavLink to={'/hour-info?'+entries.groupId}>
                                            {entries.groupName}
                                        </NavLink> :
                                        entries.groupName
                                    }
                                </td>
                                <td>{entries.hours}</td>
                            </tr>
                        )
                            
                            )}
                </tbody>
            </table>
        </div>
    )
}

export default TeacherHourPage;