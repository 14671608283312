import { useSelector } from 'react-redux';
import DeleteOneTimeBtn from './deleteOneTimeBtn.js';
import OneTimeCreation from './oneTimeCreation.js';

const OneTime = () => {
    const oneTimesList = useSelector(state => state.lessons.oneTimesList);
    const userID = useSelector(state => state.currentUser.currentUser.userID);

    return(
        <div className="onetime__wrap">
                <div className="onetime__list">
                {
                    oneTimesList.map(el=>{
                        return (
                            <div className="onetime-list__item" key={el._id}>
                                <div className="one-time__delete-btn">
                                {!el.isChecked && 
                                    <DeleteOneTimeBtn key={el._id} onetimeID={el._id}/>
                                }
                                </div>
                                <div>{el.date.slice(5, 10).split("-").reverse().join('.')}</div>
                                <div>{el.filial.name}</div>
                                <div>{el.lessonType === "test" ? "Пробное" : "Замена"}</div>
                                <div>{el.groupName}</div>
                                <div><i>{el.comment}</i></div>
                                <div>
                                    <i>{el.hours}</i>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <OneTimeCreation teacherID={userID} />
        </div>
    )
}

export default OneTime;
