const defaultState = {
    teachersArr: [],
    isLoading: false
}


export const teacherReduser = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_TEACHERS":
            return {...state, teachersArr: action.payload}

        case "SET_TEACHERS_LOADING":
            return {...state, isLoading: action.payload}
        
        case "DELETE_TEACHER_FROM_LIST":
            return {...state, teachersArr: state.teachersArr.filter(el=> el._id !== action.payload)}

        default:
            return state
    }
}