import { useEffect, useState } from "react";
import useFetch from '../hooks/useFetch.js'
import $api from "../service/api-service.js";

const GroupHours = ({progress, updateFunc, showDel}) => {
    const [showInput, setShowInput] = useState(false);
    const [manualValue, setManualValue] = useState(progress?.manualHours);
    const [nameValue, setNameValue] = useState(progress?.courseName);
    const [{response, isLoading}, doFetch] = useFetch('/progress/update/manual/'+progress?._id);
    const [progressAmount, setProgressAmount] = useState(progress?.autoHours + progress?.manualHours);

    const hoursClickHandler = () => {
        setShowInput(p=>!p);
    }

    const submitHandler = (evt) => {
        evt.preventDefault();
        doFetch({
            method: 'post',
            data: {
                manualHours: manualValue,
                courseName: nameValue
            }
        })
    }

    const deleteHandler = (evt) => {
        evt.preventDefault();
        $api.delete('/progress/delete/'+progress?._id)
            .then(updateFunc());
    }

    useEffect(()=>{
        setProgressAmount(progress?.autoHours + progress?.manualHours);
    },[progress])

    useEffect(()=>{
        if(!response){
            return;
        }
        setShowInput(false)
        setProgressAmount(response.manualHours + response.autoHours);
    },[response])

    return (
        <div className="group-hours">
            {showInput &&
                <div className="group-hours__change-wrap">
                    <form onSubmit={submitHandler} >
                        <input 
                            className="group-hours__course-name" 
                            type="text" 
                            placeholder="название курса" 
                            onChange={({target})=>{setNameValue(target.value)}} 
                            value={nameValue}
                            />
                        <span className="group-hours__manual">ручные</span>
                        <button type="button" onClick={hoursClickHandler} className="group-hours__close-btn">x</button>
                        <div className="group-manual__wrap">
                            <input name="manualHours" value={manualValue} onChange={el=>setManualValue(el.target.value)} type="number" />
                            {!isLoading && <button type="submit">Отправить</button>}
                        </div>
                    </form>
                    {showDel && <button onClick={deleteHandler}>Удалить</button>}
                </div>
            }
            <div onClick={hoursClickHandler}>{progressAmount}</div>
        </div>
    )
}

export default GroupHours