import { useDispatch } from "react-redux";
import { DeleteOneTime } from "../store/actions/lessons";

const DeleteOneTimeBtn = ({onetimeID}) => {
    const dispatch = useDispatch();

    const deleteClickHandler = () => {
        dispatch(DeleteOneTime(onetimeID));
    }

    return(
        <div className="one-time__delete-btn">
            <button className="lesson-item__remove-btn" onClick={deleteClickHandler}>X</button>
        </div>
    )
}

export default DeleteOneTimeBtn;