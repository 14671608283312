import React, { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch.js';
import { Link } from 'react-router-dom';
import convertSum from '../../../service/sumConverter.js';

const StudentsList = ({studnets, groupID, updateFunc, isManager, groupView = false}) => {
    const [{response}, doFetch] = useFetch('/student/deletefromgroup');
    const [discordShow, setDiscordShow] = useState(false);

    const deleteClickHandler = (studentID, target) => {
        doFetch({
            method: "post",
            data: {
                groupID: groupID,
                studentID: studentID
            }
        })
        target.classList.add("d_none")
    }

    const laneClickHandler = () => {
        setDiscordShow(!discordShow);
    }

    useEffect(()=>{
        if(!response) {
            return;
        }
        updateFunc()
    },[response, updateFunc])

    const reviewCall = function(el) {
        if(Array.isArray(el.reviewCall)) {
            return el.reviewCall.includes(groupID)
        }
        return false;
    }

    return(
        <ul className="student-list">
            {Array.isArray(studnets) && studnets.map(el => {
                return <li className="student-list__item" key={el._id}>
                        {isManager && groupView && <button className="student-list__del-btn" onClick={({target})=>deleteClickHandler(el._id, target)}>x</button>} 
                        {
                            isManager ?
                            <Link className={"student-list__name "+(el.balance?.sum <=0 ? "student-list__name--debet": "")} to={"/archive/"+el._id}>{el.name}</Link> :
                            <>
                                {!discordShow && <span className={"student-list__name "+(el.balance?.sum <=0 ? "student-list__name--debet": "") } onClick={laneClickHandler}>{el.name}</span>}
                                {discordShow && <span className={"student-list__name "+(el.balance?.sum <=0 ? "student-list__name--debet": "") } onClick={laneClickHandler}>{el.discordName || "Нет ника"}</span>}
                            </>
                        }
                        {reviewCall(el) && <span className='student-list__review-call' title='Нужен новый отзыв'>!</span>}
                        {
                            isManager && 
                            <span className={"student__balance " + (el.balance?.sum !== 0 ? el.balance?.sum > 0 ? "student__balance--positive" : "student__balance--negative": "")}>
                                {convertSum(el.balance?.sum)}
                            </span>
                        }
                    </li>
            })}
        </ul>
    )
}

export default StudentsList;