import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { UpdateUserField } from '../store/actions/auth';

const UserProfileCheckbox = ({fieldData}) => {
    const [inputValue, setInputValue] = useState(fieldData);
    const dispatch = useDispatch();

    useEffect(()=>{
        setInputValue(fieldData);
    },[fieldData])

    const inputChangeHandler = ({target}) => {
        setInputValue(target.checked);

        const body = {
            field: 'isReadyToOffline',
            value: target.checked
        }
        dispatch(UpdateUserField(body));
    }
    
    return (
        <input className='profile__checkbox' type='checkbox' checked={!!inputValue} onChange={inputChangeHandler} />
    )
}

export default UserProfileCheckbox;