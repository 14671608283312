import { useEffect, useState } from "react";
import $api from "../service/api-service";

const ReviewDebtors = () => {
    const [students, setStudents] = useState([])
    useEffect(()=>{
        $api.get('/review/debtors')
            .then(({data})=>{
                setStudents(data)
            })
    },[setStudents])

    return (
        <div className="container">
            <h1>Должники</h1>
            <table>
                <tbody>
                    {students && students.map(el=>(
                        <tr key={el._id+Math.random()}>
                            <td>
                                {el.teacher}
                            </td>
                            <td>
                                {el.studentName}
                            </td>
                            <td>
                                {el.name}
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>
        </div>
    )
}

export default ReviewDebtors;