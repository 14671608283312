import { useEffect, useState } from "react";
import $api from "../../../service/api-service";
import createCurMount from "../../../service/monthCreator";

function daysInMonth () {
    return 32 - new Date(new Date().getFullYear(), new Date().getMonth(), 32).getDate();
};

const AttendanceAmountList = () => {
    const [teachersData, setTeacherData] = useState();

    const [month, serMonth] = useState(createCurMount())

    useEffect(()=>{
        $api.get(`/lesson/revenue?month=${month}`)
            .then(response => setTeacherData(response.data));
    },[month])

    const changeHandler = ({target}) => {
        serMonth(target.value)
    }

    return(
        <main>
            <select className="lesson-mgmnt__mounth " name="month"
                    onChange={changeHandler}
                    value={month}
                >
                <option value="01">Январь</option>
                <option value="02">Февраль</option>
                <option value="03">Март</option>
                <option value="04">Апрель</option>
                <option value="05">Май</option>
                <option value="06">Июнь</option>
                <option value="07">Июль</option>
                <option value="08">Август</option>
                <option value="09">Сентябрь</option>
                <option value="10">Октябрь</option>
                <option value="11">Ноябрь</option>
                <option value="12">Декабрь</option>
            </select>
            <div className="attendance-amount__wrap">
                {teachersData?.offline && <RevenueAmountElem teachersData = {teachersData.offline} />}
                {teachersData?.online && <RevenueAmountElem teachersData = {teachersData.online} />}
            </div>

        </main>
    )
}

const RevenueAmountElem = ({teachersData}) => {

    return (
        <div>
            <table style={{marginBottom: 64}}>
                <thead>
                    <tr>
                        <th>Показатель</th>
                        <th>Сумма</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Оборот
                        </td>
                        <td>
                            {parseFloat(teachersData.total).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")} ₽
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Не списанно
                        </td>
                        <td>
                            {parseFloat(teachersData.unchecked).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")} ₽
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Общий
                        </td>
                        <td>
                            {parseFloat(teachersData.unchecked + teachersData.total ).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")} ₽
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Средний дневной
                        </td>
                        <td>
                            {parseFloat((teachersData.unchecked + teachersData.total) / (new Date().getDate() - 1) ).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")} ₽
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Потенциальный оборот
                        </td>
                        <td>
                            {parseFloat((teachersData.unchecked + teachersData.total) / (new Date().getDate() - 1) * daysInMonth() ).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ")} ₽
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <thead>
                    <tr>
                        <th>Сумма</th>
                        <th>Студент</th>
                        <th>Дата</th>
                    </tr>
                </thead>
                <tbody>
                    {teachersData.control.map(el=>{
                        return (
                            <tr key={el[0]+Math.random()}>
                                <td>
                                    {el[0]?.toString().replace('.', ',')}
                                </td>
                                <td>
                                    {el[1]}
                                </td>
                                <td>
                                    {el[2].slice(0,10)}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default AttendanceAmountList;