import React from 'react';
import GroupCreation from '../components/groupCreation.js';
import StudentCreation from '../components/studentCreation.js';
import TeacherCreation from '../components/teacherCreation.js';
import TeacherManagement from '../components/teacherManagement.js';
import { useSelector } from 'react-redux';

const CreatingPage = () => {
    const currentUserState = useSelector(state => state.currentUser);
    const isLead = currentUserState?.currentUser?.role === 'teamlead';

    return(
        <div className="container">
            <h1>Создание студентов и групп</h1>
            {!isLead && 
            <>
                <GroupCreation />
                <StudentCreation />
            </>
            }
            <div className="creation-page__wrap">
                <TeacherCreation />
                <TeacherManagement />
            </div>
        </div>
    )
}

export default CreatingPage;