const englishToRussianDaysOfWeek = {
    Monday: 'Пн',
    Tuesday: 'Вт',
    Wednesday: 'Ср',
    Thursday: 'Чт',
    Friday: 'Пт',
    Saturday: 'Сб',
    Sunday: 'Вс'
  };
  
  export function translateDayOfWeek(day) {
    return englishToRussianDaysOfWeek[day] || day;
  }