import { useEffect, useState } from "react";
import $api from "../service/api-service";
import { translateDayOfWeek } from "../utils/dayOfWeekUtils";
import { HashLink as Link } from 'react-router-hash-link';
import Loading from "./loading";

const TeacherSchedule = ({absoluteFilial=false, teacherId, filial='all', managerView=false}) => {
    const [schedule, setSchedule] = useState([]);
    const [, setIsMounted] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        let teacherParam = teacherId;
        let filialParam = filial;
        if(absoluteFilial) {
            filialParam = 'aball'
        }

        if(!teacherId) {
            teacherParam = 'all'
        }
        setIsLoading(true);
        setIsMounted(true);
        $api.get(`/user/user-schedule/${filialParam}/${teacherParam}`)
            .then(({data})=>{
                setSchedule(data)
            })
            .catch(error => {
                console.error('Error fetching teacher schedule:', error);
            })
            .finally(()=>setIsLoading(false))

        return () => {
            setIsMounted(false);
        };
    },[teacherId, filial, absoluteFilial])

    return(
        <div className="teacher__schedule">
            {isLoading && <Loading />}
            {schedule.map((days)=>(
                <div key={'schedule_'+days[0]._id} className="teacher-schedule__column">
                    <h3 className="teacher-schedule__headline">
                        {translateDayOfWeek(days[0]?.dayOfWeek)}
                    </h3>
                    {
                        days.map(day=>(
                            <Link to={managerView ? `/hour-info?${day.groupId}` : `/lessons#${day.groupId}`} key={day._id} className="teacher-schedule__item">
                                <div className="schedule-item__field schedule-item__filial">
                                    <i>{day.filial}</i>
                                </div>
                                <div className="schedule-item__field">
                                    <b>{day.courseName}</b>
                                </div>
                                <div className="schedule-item__field">
                                    <span>{day.groupName}</span>
                                </div>
                                <div>
                                {!teacherId && 
                                    <div className="schedule-item__field">
                                        {day.teacherName}
                                    </div>
                                }
                                    {day.time.startTime} – {day.time.endTime} 
                                </div>
                            </Link>
                        ))
                    }
                </div>
            ))}
        </div>
    )
}

export default TeacherSchedule;