import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const MainPage = () =>{
    const currentUserState = useSelector(state=>state.currentUser);

    return(
        <div>
            <div className="container">
                <div className="main-page-headline__wrap">
                    <h1 className="main-page__headline">
                        Приветствую в Смэше! Не забудь отметить занятие ;)
                    </h1>
                </div>
                <div className="main-page-description__wrap">
                    <p className="main-page__description">SmaSh – это специальная платформа для преподавателей YesCoding, которая поможет нам работать эффективнее и удобнее.</p>
                </div>
                {!currentUserState.isLoggedIn && <div className="main-page-description__wrap">
                    <NavLink to="/login" className="main-page-btn">Войти в аккаунт</NavLink>
                </div> }
                {currentUserState.isLoggedIn &&
                <div className="main-page-description__wrap main-page__tolesson-btn">
                    { currentUserState.currentUser.isAdmin ? 
                        <NavLink to="/lessons-check" className="main-page-btn">Отметить</NavLink>:
                        <NavLink to="/lessons" className="main-page-btn">Отметить</NavLink> 
                    }
                </div> 
                }
            </div>
            <div className="main-page__img-wrap">
            </div>            
        </div>
    )
}

export default MainPage;