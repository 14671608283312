import { useState } from "react";
import $api from "../../../service/api-service";

const LessonsLength = ({lessonsLength, groupId}) => {
    const [inputValue, setInputValue] = useState(lessonsLength);
    const [hideBtn, setHideBtn] = useState(true);

    const valueChangeHandler = ({target}) => {
        setInputValue(target.value);
        setHideBtn(false)
    }

    const submitHandler = (evt) => {
        evt.preventDefault();
        setHideBtn(true);
        $api.patch('/group/change/lessons-length/'+groupId,
        {lessonsLength: inputValue})
    }

    return( 
        <form className="hour-info__lessons-length" onSubmit={submitHandler}>
            <label>Длит. урока: </label>
            <input type="number" step={0.25} onChange={valueChangeHandler} value={inputValue}/>
            {
                !hideBtn &&
                <button type="submit">Сохранить</button>
            }
        </form>
        )
}

export default LessonsLength;