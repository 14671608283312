import React, {useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import useFetch from '../hooks/useFetch.js'
import FilialCreation from './filialSelect.js';
import $api from '../service/api-service.js';

const StudentCreation = () => {
    const [{ response, error }, doFetch] = useFetch('/student/create');
    const userRole = useSelector(state => state.currentUser.currentUser?.userRole)

    const [userData, setUserData] = useState({
        userName: "", groupId: "", discordName: "", contract: "", recomendSum: "", email: ""
    });

    const [message, setMessage] = useState("");

    const changeHandler = useCallback(({target})=>{
        setUserData({...userData, [target.name]: target.value});
    },[userData]);

    useEffect(()=>{
        if(!response){
            return;
        }

        if(response.groupId){
            setMessage(
                <>
                    <p>Студент <b>{response.name}</b> добавлен к группе <b>{response.groupId[response.groupId.length-1]}</b></p>
                </>
            )
        } else {
            setMessage(<><p>Студент <b>{response.name}</b> создан</p></>);
        }

        setUserData(p=>{return {...p, userName: "", discordName: "", contract: "", email: ""}});
    },[response]);

    useEffect(()=>{
        if(!error){
            return;
        }

        setMessage(<div>{error.response.data?.message}</div>);
    }, [error])


    const submitHandler = useCallback((evt)=>{
        evt.preventDefault();

        if(userData.userName.length > 0){
            doFetch({
                method: 'post',
                data: {
                    name: userData.userName,
                    groupId: userData.groupId,
                    discordName: userData.discordName,
                    filials: userData?.filial ? [userData?.filial] : [userRole?.filialAccess[0]._id],
                    contract: userData.contract,
                    recomendSum: userData.recomendSum,
                    email: userData.email
                }
              });
        }else{
            setMessage("Имя студента не может быть пустым");
        }
    },[userData, doFetch, userRole])

    const generateContractHandler = () => {
        $api.get('/student/generate-contract-number')
            .then(({data})=>{
                setUserData({...userData, contract: data.contract});
            })
    }

    return(
        <div className="group-creation">
            <div>
                <h2>Создание студента</h2>
                <form onSubmit={submitHandler} autoComplete='off'>
                    <div className="creating-page__input-wrap"> 
                        <FilialCreation filialAccess={userRole?.filialAccess} changeHandler={changeHandler} />
                    </div>
                    <div className="creating-page__input-wrap"> 
                        <input type="text" required placeholder="Имя студента" name="userName" value={userData.userName} onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap"> 
                        <input type="text" required placeholder="Номер договора" name="contract" value={userData.contract} onChange={changeHandler}/> 
                        <button type="button" onClick={generateContractHandler} className='student-card__notify-btn'>Создать</button>
                    </div>
                    <div className="creating-page__input-wrap"> 
                        <input type="email" placeholder="Email родителя" name="email" value={userData.email} onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <input type="text" required placeholder="Ник дискорд" name="discordName" value={userData.discordName} onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <input type="number" placeholder="Рекомендуемая сумма" name="recomendSum" value={userData.recomendSum} onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <input type="text" placeholder="id группы, если уже есть" name="groupId" value={userData.groupId} onChange={changeHandler}/>
                    </div>
                    <div className="creating-page__input-wrap">
                        <button className="yc_button" type='submit'>Создать</button>
                    </div>
                </form>

            </div>
            <div className="group-creation__log student-creating">
                <h3>Логи</h3>
                <div>
                    {message}
                </div>
            </div>
        </div>
    )
}

export default StudentCreation;