import React, { useCallback, useEffect, useState } from "react";
import useFetch from '../../../hooks/useFetch.js';
import GroupArchiveItem from "./groupArchiveitem.js";
import Loading from "../../../components/loading.js";

const GroupArchive = () => {
    const [{response, isLoading}, doFetch] = useFetch('/group/archived');
    const [groupsList, setGroupsList] = useState([])

    useEffect(()=>{
        doFetch()
    },[doFetch])

    const DeleteItem = useCallback((itemID) => {
        setGroupsList(g => g.filter(el=>el.props.groupId !== itemID))
    },[])

    useEffect(()=>{
        if(!response) {
            return;
        }
        setGroupsList(response.map(el=>{
            return <GroupArchiveItem key={el._id} gruopName={el.name} groupId={el._id} updateFunc={DeleteItem}/>
        }))

    },[response, DeleteItem])

   

    return (
        <div className="archive-page__group-wrap">
            <h3>Архив групп</h3>
            {isLoading && <Loading />}
            {groupsList}
        </div>
    )
}

export default GroupArchive;