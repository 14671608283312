import $api from "../../service/api-service";
import { ShowMessage } from "./toast";

export const CreateHomingAcc = (userID) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.patch('/student/create-lk/'+userID)
            .then(data=>{
                if(data.status === 200) {
                    dispatch(ShowMessage("Аккаунт успешно создан"));
                }
            })
            .then(()=>{
                dispatch(GetStudentData(userID));
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_STUDENT_LOADING", payload: false});
            })
    }
}

export const updatePrice = (userId, priceId, params) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.put('/price/update/'+priceId, {params: params})
        .then(response => {
            if(response.status === 200) {
                dispatch(ShowMessage(response.data.message));
            }
        })
        .then(()=>{
            dispatch(GetStudentData(userId));
        })
        .catch(err=>{
            dispatch(ShowMessage("Ошибка сервера", "error"));
         })
         .finally(()=>{
             dispatch({type: "SET_STUDENT_LOADING", payload: false});
         })
    }
}

export const recalcPrice = (priceId, studentID) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.get(`/price/recalc/${priceId}/${studentID}`)
        .then(response => {
            if(response.status === 200) {
                dispatch(ShowMessage(response.data.message));
            }
        })
        .then(()=>{
            dispatch(GetStudentData(studentID));
        })
        .catch(err=>{
            dispatch(ShowMessage("Ошибка сервера", "error"));
         })
         .finally(()=>{
             dispatch({type: "SET_STUDENT_LOADING", payload: false});
         })
    }
}

export const CreateParent= (userID) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.post('/parent/create-by-student/', {
            studentId: userID
        })
            .then(data=>{
                if(data.status === 200) {
                    dispatch(ShowMessage("Родитель создан"));
                }
            })
            .then(()=>{
                dispatch(GetStudentData(userID));
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_STUDENT_LOADING", payload: false});
            })
    }
}

export const ChangePaymentCompany = (studentId, toYesProg) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.post('/student/update-payment-company/', {
            studentId: studentId, 
            setActiveStatus: toYesProg
        })
        .then(data=>{
            if(data.status === 200) {
                dispatch({type: "SET_STUDENT_DATA", payload: { payToYesProg: data.data.status }});
                dispatch(ShowMessage("Ссылка создана"));
            }
        })
        .catch(err=>{
            dispatch({type: "SET_STUDENT_DATA", payload: { paymentLink: 'ошибка' }});
        })
        .finally(()=>{
            dispatch({type: "SET_STUDENT_LOADING", payload: false});
        })
    }
}

export const CreatePaymentLink = (contract, amount, email) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.post('/student/create-payment-link/', {
            contract: contract, 
            amount: amount, 
            email: email
        })
        .then(data=>{
            if(data.status === 200) {
                dispatch({type: "SET_STUDENT_DATA", payload: { paymentLink: data.data.url }});
                dispatch(ShowMessage("Ссылка создана"));
            }
        })
        .catch(err=>{
            dispatch({type: "SET_STUDENT_DATA", payload: { paymentLink: 'ошибка' }});
        })
        .finally(()=>{
            dispatch({type: "SET_STUDENT_LOADING", payload: false});
        })
    }
}

export const CreateQrPaymentLink = (contract, amount, email) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.post('/student/create-qrpayment-link/', {
            contract: contract, 
            amount: amount, 
            email: email
        })
        .then(data=>{
            if(data.status === 200) {
                dispatch({type: "SET_STUDENT_DATA", payload: { paymentLink: data.data.Data }});
                dispatch(ShowMessage("Ссылка создана"));
            }
        })
        .catch(err=>{
            dispatch({type: "SET_STUDENT_DATA", payload: { paymentLink: 'ошибка' }});
        })
        .finally(()=>{
            dispatch({type: "SET_STUDENT_LOADING", payload: false});
        })
    }
}

export const GetStudentData = (studentID) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.get(`/student/${studentID}`)
            .then(data=>{
                if(data.status === 200) {
                    dispatch(StudentDataActionCreator(data.data));
                    dispatch({type: 'SET_LAST_NOTIFICATION', payload: {
                        lastNotificationDate: data.data.notification?.createdAt,
                        lastNotificationId: data.data.notification?._id
                    }}) 
                }
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_STUDENT_LOADING", payload: false});
            })
    }
}

export const GetStudentsList = () => {
    return dispatch => {
        $api.get(`/student/`)
            .then(data=>{
                if(data.status === 200) {
                    dispatch({type: "SET_STUDENTS_LIST", payload: data.data})
                }
            }).catch(err=>{
                dispatch(ShowMessage("Ошибка сервера", "error"));
            });
    }
}


export const StudentDataActionCreator = (data) => {
    return {
        type: "SET_STUDENT_DATA",
        payload: {
            _id: data._id || "",
            name: data.name || "",
            studentID: data._id || "",
            email: data.email|| "",
            contract: data.contract || "",
            age: data.age || "",
            homingConnect: data.homingConnect || false,
            discordName: data.discordName || "",
            recomendSum: data.recomendSum || "",
            parentName: data.parentName || "",
            parent: data.parent || "",
            groups: data.groups || [],
            balanceSum: data?.balanceSum,
            filials: data.filials || [],
            isActive: data.isActive || "",
            paymentLink: "",
            payToYesProg: data.payToYesProg || false
        }
    }
}

export const SetActiveStudent = (status, userId) => {
    return dispatch => {
        const {checked} = status;
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.patch(`/student/acitve/${userId}`, { status: checked })
        .then(()=>{
                dispatch({type: "SET_STUDENT_DATA", payload: { isActive: checked }});
                dispatch({
                    type: "UPDATE_STUDENT_IN_LIST",
                    payload: {
                        id: userId,
                        field: 'isActive',
                        value: checked
                    }
                })
            })

        dispatch({type: "SET_STUDENT_LOADING", payload: false});
    }
}

export const DeleteStudent = (studentID) => {
    return dispatch => {
        dispatch({type: "SET_STUDENT_LOADING", payload: true});
        $api.delete(`/student/${studentID}`)
            .then(data=>{
                if(data.status === 200) {
                    dispatch(StudentDataActionCreator({}));
                    dispatch({type: "DELETE_STUDENT_FROM_LIST", payload: studentID});
                    dispatch(ShowMessage("Студент удален")); 
                }
            })
            .catch(err=>{
                console.log(err)
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_STUDENT_LOADING", payload: false});
            })
    }
}