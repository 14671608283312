import React, { useEffect, useState } from "react";
import useFetch from '../hooks/useFetch.js';
import Loading from "./loading.js";

const TEACHERS_RATE = {
    groupOnline: 550,
    indivOnline: 450,
    groupInClass: 700,
    indivInClass: 600,
    oneTimeOnline: 450
}

const TeacherInfo = ({ teacher, curMonth = new Date().getMonth()+1, curYear = new Date().getFullYear() }) => {
    const [{response, isLoading}, doFetch] = useFetch(`/lesson/count-lessons/${teacher.userID}/${curMonth}/${curYear}`);
    const [hours, setHours] = useState({
        hours: 0,
        sum: 0,
        reviewCalls: false
    });


    useEffect(()=>{
        doFetch()
    },[doFetch])

    useEffect(()=>{
        if(!response) {
            return
        }
        const { onlineOneTimerHours, inClassOneTimerHours, groupInClass, groupOnline, indivInClass, indivOnline, online, inClass, reviewCalls } = response;
        const sum = groupOnline * TEACHERS_RATE.groupOnline + 
            indivOnline * TEACHERS_RATE.indivOnline +
            groupInClass * TEACHERS_RATE.groupInClass +
            indivInClass * TEACHERS_RATE.indivInClass + 
            onlineOneTimerHours * TEACHERS_RATE.oneTimeOnline + 
            inClassOneTimerHours * TEACHERS_RATE.groupInClass;

        setHours(p=> {return {...p, sum: sum, hours: online+inClass, reviewCalls: reviewCalls}});
    },[response])

    return (
        <div className="teacher-info">
            <div className={"teacher-info__item teacher-info__hours "} onClick={doFetch}>
                {isLoading && <Loading style={{marginRight: "43px",
                marginBottom: "25px"}} width={50}/>}
                {!isLoading &&
                <>
                    <p className="teacher-info__description">проведено</p>
                    <p className="teacher-info__value">{hours.hours} часов</p>
                </>
                }
            </div>
            <div className={"teacher-info__item teacher-info__cash "+(hours.reviewCalls ? "need_reviews": "")}>
                <p className="teacher-info__description">заработано</p>
                <p className="teacher-info__value">{hours.sum} руб</p>
            </div>
        </div>
    )
}

export default TeacherInfo;