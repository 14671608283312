import $api from "../../service/api-service";
import { ShowMessage } from "./toast";

export const GetTeacherSelectList = () => {
    return dispatch => {
        dispatch({type: "SET_LOADING", payload: true});
        $api.get('/user/teacher/all')
            .then(data=>{
                if(data.status === 200) {
                    dispatch({type: 'SET_TEACHERS_SELECT', payload: data.data});
                }
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_LOADING", payload: false});
            })
    }
}