import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChangeArchiveUserStatus, GetTeachersList } from "../store/actions/teachers";
import $api from "../service/api-service";
import { isAdminSelector } from "../store/currentUser";

const TeachersPage = () => {
    const dispatch = useDispatch()
    const teacher = useSelector(state => state.teachers);
    const isAdmin = useSelector(isAdminSelector);
    const [search, setSearch] = useState('');

    useEffect(()=>{
        dispatch(GetTeachersList());
    },[dispatch]);

    const linkGen = (link) => {
        return "https://"+link?.replace('https://', "")
    }

    const archBtnClickHandler = (userID)=> {
        dispatch(ChangeArchiveUserStatus(userID));
    }

    const addToTeam = (id, newTeacher) => {
        $api.patch('/team/add-to-squad/'+id, {newTeacher: newTeacher});
    }

    const searchChangeHandler = (evt) => {
        evt.preventDefault();

        setSearch(evt.target.value);
    }

    return(
        <div className="container">
            <h1>Страница училок</h1>
            <input type="text" placeholder="Поиск" className="student-list__search-field" value={search} onChange={searchChangeHandler} />
            <table className="teachers__table">
                <tbody>
                {teacher.teachersArr.filter(el=>new RegExp(search, 'gi').test(el.userName))?.map(el=>{
                    const vkLink = linkGen(el.vkLink);
                    return(
                        <tr key={el._id}>
                            <td className="teacher__name-column">
                                <b>
                                    {el.userName}
                                </b>
                            </td>
                            <td>
                                <ul>
                                    { el.phoneNumber && <li>📞: {el.phoneNumber}</li>}
                                    { el.discordName && <li>disk: {el.discordName}</li>}
                                    { el.telegramLink && <li>tg: {el.telegramLink}</li> }
                                    { el.vkLink && 
                                        <li>vk: {
                                            el.vkLink?.includes('.com') ?
                                            <a target="_blank" rel="noreferrer" href={vkLink}>
                                                {el.vkLink}
                                            </a>
                                            :
                                            el.vkLink
                                            }
                                        </li> 
                                    }
                                </ul>
                            </td>
                            <td>
                                {el.isReadyToOffline?
                                "Готов" : 
                                "Не готов"}
                            </td>
                            {
                                el.district &&
                                <td>
                                    {el.district}
                                </td>
                            }
                            {isAdmin && !el.team &&
                                <td>
                                    <button onClick={()=>addToTeam('623468892edc9650dbfcff34', el._id)}>Стас</button>
                                    <button onClick={()=>addToTeam('619b8494608beccf9c805264', el._id)}>Петя</button>
                                    <button onClick={()=>addToTeam('619b8495608beccf9c80526a', el._id)}>Влад</button>
                                </td>
                            }
                            <td className="teachers-arch-btn__wrap">
                                <button onClick={()=>archBtnClickHandler(el._id)}>
                                    arch
                                </button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}

export default TeachersPage;