import { useState } from "react";
import $api from "../service/api-service";

const CourseOfLesson = ({lessonId, courseProgress}) => {
    const [course, setCourse] = useState(
        courseProgress.find(el => {
            return el.lessonsList?.includes(lessonId)
        })
    );

    const changeCourseOfLesson = ({target}) => {
        FetchChangeLesson(course?._id ,target.value);
        if(target.value !== "recover") {
            return setCourse(target.value);
        }

        setCourse(false)
    };

    const btnClickHandler = () => {
        setCourse(courseProgress[0]);
        FetchChangeLesson(courseProgress[0]._id, courseProgress[0]._id)
    };

    const FetchChangeLesson = (prevId, newId) => {
        $api.post('/progress/change-lesson/'+prevId,
        {
            lessonId: lessonId,
            newProgress: newId
        })
    }

    return (
        <div className="course-of-lesson__wrap">
            {course &&
                <select onChange={changeCourseOfLesson} value={course?._id}>
                    <option value="recover">–</option>
                    {courseProgress.map(el=><option key={el._id} value={el._id}>{el.courseName}</option>)}
                </select>            
            }
            {!course &&
                <button onClick={btnClickHandler}>+</button>
            }
        </div>
    )
}

export default CourseOfLesson;