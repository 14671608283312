import { useState } from "react";
import $api from "../../../service/api-service";
import { useDispatch } from "react-redux";

const ScheduleCreator = ({groupId}) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        startTime: '',
        endTime: '',
        dayOfWeek: 'Monday', // Значение по умолчанию
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const newScheduleHandler = (e) => {
        e.preventDefault();
        
        $api.post('/schedule/create/'+groupId,
        {
          group: groupId,
          dayOfWeek: formData.dayOfWeek,
          startTime: formData.startTime,
          endTime: formData.endTime 
        })
        .then(({data})=> {
          dispatch({type: "SET_GROUP_SCHEDULE", payload: data});
        }).catch(err=>console.log(err))
      };

      return(
        <form className="schedule__creating-form" onSubmit={newScheduleHandler}>
            <select
                id="dayOfWeek"
                name="dayOfWeek"
                value={formData.dayOfWeek}
                onChange={handleChange}
            >
                <option value="Monday">Понедельник</option>
                <option value="Tuesday">Вторник</option>
                <option value="Wednesday">Среда</option>
                <option value="Thursday">Четверг</option>
                <option value="Friday">Пятница</option>
                <option value="Saturday">Суббота</option>
                <option value="Sunday">Воскресенье</option>
            </select>
            <input
                type="time"
                name="startTime"
                placeholder=""
                value={formData.startTime}
                onChange={handleChange}
            />
            <input
                type="time"
                name="endTime"
                placeholder=""
                value={formData.endTime}
                onChange={handleChange}
            />
        <button type="submit">Создать</button>
    </form>
      )
}

export default ScheduleCreator;