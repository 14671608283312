import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router} from 'react-router-dom'

import Routes from './routes.js'
import Topbar from './components/topbar'
import Toaster from './components/toaster'
import CurrentUserChecker from './components/currentUserChecker.js';
import { Provider } from 'react-redux';
import { store } from './store/index.js'

import './index.css'

const App = () => {
  return (
    <Provider store={store}>
     <CurrentUserChecker>
      <Router>
        <Toaster />
        <Topbar />
        <Routes/>
      </Router>
     </CurrentUserChecker>
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))