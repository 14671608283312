import React, { useEffect } from "react";
import useFetch from '../hooks/useFetch.js';

const LessonsCheckComponent = ({ updateFunction, isChecked, isManager, lessonID}) => {
    const [{response}, doFetch] = useFetch('/lesson/status/'+lessonID);

    const statusCheckHandler = ({target})=>{
        
        doFetch({
            method: 'put',
            data: {
                isChecked: target.checked
            }
        })  

        
    }

    useEffect(()=>{
        if(!response){
            return
        }

        updateFunction();

    },[response, updateFunction])

    return(
        <div>
            {isManager && 
                <div className="lessons__manager-check-wrap"> 
                    <input className="lessons__manager-check"  type="checkbox" defaultChecked={isChecked} onChange={statusCheckHandler} />
                </div>
            }
        </div>
    )
}

export default LessonsCheckComponent;