import { useState } from "react";
import { useDispatch } from "react-redux";
import $api from "../../../service/api-service";
import { ShowMessage } from "../../../store/actions/toast";

const ReviewPageApproveBtn = ({ approveStatus, reviewId }) => {
    const [approveProxyStatust, setApproveProxyStatus] = useState(approveStatus);
    const dispatch = useDispatch();

    const changeApproveHandler = (status=true) => {
        $api.put('/review/approve/'+reviewId,
            {status: status})
            .then(response=>{
                dispatch(ShowMessage(response.data.message, 'success'))
                setApproveProxyStatus(status)
            })
    }

    return (
        <>
        { approveProxyStatust ?
            <p onClick={()=>{changeApproveHandler(false)}}>Одобрено</p>
        :
            <button onClick={()=>{changeApproveHandler(true)}} className="review__approve-btn">Одобрить</button> 
        }

        </>
    )
}

export default ReviewPageApproveBtn;