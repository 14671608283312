const defaultState = {
    teachersList: [{
        _id: "",
        userName: ""
    }],
}


export const teacherSelectReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_TEACHERS_SELECT":
            return {...state, teachersList: action.payload}

        default:
            return state
    }
}