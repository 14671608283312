import React, { useEffect } from "react";
import useFetch from '../../../hooks/useFetch.js';
import UnArchiveBtn from "../../../components/unArchiveBtn.js";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min.js";

const GroupArchiveItem = ({ gruopName, groupId, updateFunc }) => {
    const [{response}, doFetch] = useFetch('/group/'+groupId);

    const deleteBtnHandler = () => {
        doFetch({
            method: "delete"
        })
    }

    useEffect(()=>{
        if(!response){
            return;
        }
        updateFunc(groupId);
    },[response, updateFunc, groupId])

    return(
        <div className="group-list__item">
            <NavLink to={"/hour-info?"+groupId} className="group-item__name">{gruopName}</NavLink>
            <button onClick={deleteBtnHandler}>x</button>
            <UnArchiveBtn updateFunc={updateFunc} groupID={groupId}/>
        </div>
    )
}

export default GroupArchiveItem