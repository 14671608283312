import $api from "../../service/api-service"
import { ShowMessage } from "./toast";

export const loginUser = (email, password) => {
    return dispatch => {
        $api.post('/auth/login', {email, password})
            .then((response => {
                localStorage.setItem('token', response.data.token);
                const user = setUserToLC(response.data);
                dispatch({type: 'SET_AUTHORIZED', payload: user});
            }))
            .catch((error)=>{
                dispatch(ShowMessage(error.response.data.message, "error"));
            })
    }
}

const setUserToLC = response => {
    const user = {
        isAdmin: response.isAdmin,
        userName: response.userName,
        userID: response.userID,
        userRole: response.userRole,
        role: response.role
    }
    localStorage.setItem('user', JSON.stringify(user));
    return user;
}

export const GetUser = () => {
    return dispatch => {
        $api.get('/user/current-user/', null)
            .then((response => {
                dispatch({type: 'SET_USER', payload: response.data});
            }))
            .catch((error)=>{
                dispatch(ShowMessage(error.response.data.message, "error"));
            })
    }
}

export const UpdateUserField = (body) => {
    return dispatch => {
        $api.patch('/user/current-user/', body)
            .then((response => {
                dispatch({type: 'SET_USER', payload: response.data});
            }))
            .catch((error)=>{
                dispatch(ShowMessage(error.response.data.message, "error"));
            })
    }
}