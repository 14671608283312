import { useEffect, useState } from "react"
import $api from "../service/api-service";
import useFetch from '../hooks/useFetch';

const StudentProgressDescription = ({studentID, _id, lesson, matchedObj}) => {
    const [courseList, setCouseList] = useState([]);
    const [selectValue, setSelectValue] = useState(_id);
    const [courseIsChanging, setCourseIsChanging] = useState(false);
    const [courseName, setCourseName] = useState(matchedObj?.studentProgress?.courseName);
    const [{response}, doFetch] = useFetch('/progress/student-courses/'+studentID);

    useEffect(()=>{
        if(!response) return;
        setCouseList(response);
    },[response])

    const toggleChangeStatus = () => {
        doFetch();
        setCourseIsChanging(p=>!p)
    }

    const chageHandler = ({target}) => {
        const {value} = target
        setSelectValue(value);

        $api.post('/progress/change-student-course',
        {
            lesson: lesson,
            prev: _id,
            new: target.value,
        })
            .then(()=> {
                setCourseName(courseList.find(el=>el._id===value).courseName);
                toggleChangeStatus()
            })
    }


    return (
        <div className="student-progress__name">
            {!courseIsChanging ? 
            <span onClick={toggleChangeStatus}>{ courseName }</span>
            :   
            <div>
                <select className="student-progress__name-changer" value={selectValue} onChange={chageHandler}>
                    {courseList.map(el=>{
                        return  <option value={el._id} key={el._id}>{el.courseName} ({el.autoHours+el.manualHours})</option>
                    })}
                </select>
                <button onClick={toggleChangeStatus}>x</button>
            </div>
            }
        </div>
    )
}

export default StudentProgressDescription;