import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SaveAttandance } from "../store/actions/lessons";
import StudentProgressDescription from "./studentProgressDescription";
import $api from "../service/api-service";
import { isAdminSelector } from "../store/currentUser";

const AttandanceSelectItem = ({student, lesson, lastElem, groupsView, updateFunction, studentSum}) => {
    const  isManager = useSelector(isAdminSelector);
    const matchedObj = lesson.attendance1.find(el=> el.studentId?._id ? el.studentId?._id  === student._id :  el.studentId  === student._id);
    const {autoHours, manualHours, courseName, totalHours} = matchedObj?.studentProgress || {};
    const statusC = matchedObj === undefined ? "neural" :
        matchedObj.presence ? "yes" : "no";

    const [status, setStatus] = useState(statusC)
    
    const dispatch = useDispatch();

    const checkboxHandler = (target) => {
        const studentsAttandance = {
            studentId: student._id,
            attendanceId: matchedObj?._id,
            presence: target.value
        }
        dispatch(SaveAttandance(lesson, studentsAttandance, updateFunction))
        setStatus(target.value)
    }

    const recalcHandler = useCallback(() => {
        $api.patch(`/lesson/recalculate-lesson/${matchedObj?._id}`, 
        {
            groupId: matchedObj?.studentProgress?.groupID,
            studentId: matchedObj?.studentId._id
        }).then(({data})=>{
            dispatch({type: 'UPDATE_LESSON_IN_LIST', payload: data})
            })
    },[matchedObj?._id, matchedObj?.studentId._id, matchedObj?.studentProgress?.groupID, dispatch])

    return(
        <div className={groupsView ? "group__attandance-wrap" : "attandance-wrap"}>
            <div className="attandance-select__wrap">
                {
                    (!isManager && (status === 'neural' && !lesson.isChecked && studentSum <= 0)) ?
                    <div className="attandance-select__without-pay">

                    </div> :
                    <select disabled={lesson.isChecked}
                            onChange={({target})=>checkboxHandler(target, student._id)} 
                            defaultValue={status} 
                            className={"lesson-attandance__box attandance__box--"+status}>
                        <option value="neural"> </option>
                        <option value="yes">+</option>
                        <option value="no">×</option>
                    </select>
                }
                {!groupsView &&
            <div className="attendance__price" onClick={recalcHandler}>
                {matchedObj?.price}
            </div>
            }
            </div>
            {matchedObj && 
            <>
            { courseName &&
                groupsView ? 
                    lastElem &&
                        <div className="student-progress__name">
                            <p className="student-progress__name-wrap">{courseName}</p>
                            <div className="course-progress__wrap">
                                <div className="progress-bar-indicator" style={{width: `${(autoHours + manualHours) / totalHours * 100}%`}}></div>
                                    <p className="progress__amount">{autoHours + manualHours} / {totalHours}</p>    
                            </div>
                        </div>
                    :
                <StudentProgressDescription _id={matchedObj?.studentProgress?._id} matchedObj={matchedObj} lesson={matchedObj?._id} studentID={matchedObj?.studentProgress?.studentID}/>
            }

            </>}
        </div>
    )
}

export default AttandanceSelectItem;