import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import MainPage from './pages/mainPage';

import AuthPage from './pages/authPage.js'
import LessonsPage from './pages/lessonsPage';
import CreatingPage from './pages/creatingPage';
import LessonManagementPage from './pages/lessonManagementPage';
import ArchivePage from './pages/archivePage';
import { useSelector } from 'react-redux';
import ProfilePage from './pages/profilePage';
import TeachersPage from './pages/teachers';
import HourInfo from './pages/hourInfoPage';
import RewiewPage from './pages/reviewPage/index';
import AnalyticsPage from './pages/analyticsPage'
import IncomesPage from './pages/incomesPage';
import BalancePage from './pages/balancePage/index.js';
import DiskPage from './pages/diskPage';
import TeacherHourPage from './pages/teachersHoursPage.js';
import ReviewDebtors from './pages/reviewDebtorsPage.js';
import SchedulePage from './pages/schedulePage.js';
import ManualMoneyAdd from './pages/manualMoneyAdd.js';
import Payroll from './pages/payrollPage.js';

const Routes = () => {
  const currentUserState = useSelector(state => state.currentUser);
  const isLead = currentUserState?.currentUser?.role === 'teamlead';
  const isAdmin = currentUserState?.currentUser?.isAdmin;
  const isLoggedIn = currentUserState.isLoggedIn;
  return (
    <Switch>
        <Route path="/" component={MainPage} exact />

        {isLoggedIn && <Route path="/profile" component={ProfilePage} />}
        {isLoggedIn && <Route path="/reviews" component={RewiewPage} />}
        {isLoggedIn && <Route path="/lessons" component={LessonsPage} /> }
        {isLoggedIn && <Route path="/disk" component={DiskPage} /> }
        
        {isAdmin && <Route path="/lessons-check" component={LessonManagementPage} />}
        {isAdmin && <Route path="/archive" component={ArchivePage} />}
        {isAdmin && <Route path="/hour-info" component={HourInfo} />}    
        {isAdmin && <Route path="/incomes" component={IncomesPage} />}    
        {(isAdmin || isLead) && <Route path="/creation" component={CreatingPage} />}
        {(isAdmin || isLead) && <Route path="/teachers" component={TeachersPage} />}
        {(isAdmin || isLead) && <Route path="/review-debtors" component={ReviewDebtors} />}
        {(isAdmin || isLead) && <Route path="/schedule" component={SchedulePage} />}
        {isAdmin && <Route path="/anltcs" component={AnalyticsPage} />}
        {isAdmin && <Route path="/balances" component={BalancePage} />}
        {isAdmin && <Route path="/teachers-hours/:id/:month" component={TeacherHourPage} />}
        {isAdmin && <Route path="/manual-money" component={ManualMoneyAdd} />}
        {isAdmin && <Route path="/payroll" component={Payroll} />}
        
        {!isLoggedIn && <Route path="/login" component={AuthPage} />}

        <Redirect to="/" />
    </Switch>
  )
  
}
export default Routes
