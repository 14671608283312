import $api from "../../service/api-service";
import { ShowMessage } from "./toast";

export const GetTeachersList = () => {
    return dispatch => {
        dispatch({type: "SET_TEACHERS_LOADING", payload: true});
        $api.get('/user/teacher/all')
            .then(data=>{
                if(data.status === 200) {
                    dispatch({type: 'SET_TEACHERS', payload: data.data});
                }
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_TEACHERS_LOADING", payload: false});
            })
    }
}

export const ChangeArchiveUserStatus = (userID, status=true) => {
    return dispatch => {
        dispatch({type: "SET_TEACHERS_LOADING", payload: true});
        $api.patch('/user/archive/' + userID, {setArchive: status})
            .then(data=>{
                if(data.status === 200) {
                    dispatch({type: 'DELETE_TEACHER_FROM_LIST', payload: userID});
                }
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_TEACHERS_LOADING", payload: false});
            })
    }
}