import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useFetch from "../hooks/useFetch.js";
import AlarmComponent from './alarmComponent.js';
import Loading from "./loading.js";

const ArchiveGroupBtn = ({ groupID }) => {

    const [{response}, doFetch] = useFetch("/group/archive");
    const [showing, setShowing] = useState(true)
    const dispatch = useDispatch()

    const archiveBtnHandler = () => {
        setShowing(false)
        doFetch({
            method: "post",
            data: {
                groupID: groupID
            }
        })
    }

    const alarmChild = useRef();

    useEffect(()=>{
        if(!response) {
            return;
        }

        dispatch({type: "REMOVE_FROM_LESSONS_LIST", payload: groupID})
    },[response, groupID, dispatch])

    return(
        <div>
            {showing && <button className="lessons-item__archive-btn" onClick={()=>{alarmChild.current.alarmCall()}}>Арх</button>}
            <AlarmComponent cb={archiveBtnHandler} ref={alarmChild} description={"Вы действительно хотите архивировать группу? Вернуть её будет сложно..."}/>
            {!showing && <Loading />}
        </div>
    )
}

export default ArchiveGroupBtn;