import React, { useEffect } from "react";
import useFetch from '../hooks/useFetch.js';

const UnArchiveBtn = ({groupID, updateFunc}) => {
    const [{response}, doFetch] = useFetch('/group/unarchive');

    const unarchiveBtnHandler = () => {
        doFetch({
            method: "post",
            data:{
                groupID: groupID
            }
        })
    }

    useEffect(()=>{
        if(!response) {
            return
        }

        updateFunc(groupID)
    },[response,groupID, updateFunc])

    return (
        <button onClick={unarchiveBtnHandler}>восстановить</button>
    )
}

export default UnArchiveBtn;