import $api from "../../service/api-service";
import { addFile, deleteFileAction, setPopupDisplay } from "../disk";
import { addUploadFile, changeUploadFile, showUploader } from "../upload";
import { ShowMessage } from "./toast";

export const GetFilesList = (dirId, sort) => {
    return dispatch => {
        dispatch({type: "SET_FILES_LOADING", payload: true});
        let url = '/file';
        if (dirId) {
            url = `/file?parent=${dirId}`
        }
        if (sort) {
            url = `/file?sort=${sort}`
        }
        if (dirId && sort) {
            url = `/file?parent=${dirId}&sort=${sort}`
        }
        $api.get(url)
            .then(data=>{
                if(data.status === 200) {
                    dispatch({type: 'SET_FILES', payload: data.data});
                }
            })
            .catch(err=>{
               dispatch(ShowMessage("Ошибка сервера", "error"));
            })
            .finally(()=>{
                dispatch({type: "SET_FILES_LOADING", payload: false});
            })
    }
}

export function createDir(dirId, name) {
    return async dispatch => {
        dispatch({type: "SET_FILES_LOADING", payload: true});
        $api.post('/file', {
                name,
                parent: dirId,
                type: 'dir'
            })
        .then(data=>{
            if(data.status === 200) {
                dispatch(addFile(data.data))
                dispatch(setPopupDisplay('none'));
            }})
        .catch(err=>{
            dispatch(ShowMessage("Ошибка сервера", "error"));
        })
        .finally(()=>{
            dispatch({type: "SET_FILES_LOADING", payload: false});
        })
    }
}

export async function downloadFile(file) {
    const response = await $api.get(`file/download?id=${file._id}`, {
        responseType: "blob"
      })

    if (response.status === 200) {
        const downloadUrl = window.URL.createObjectURL(response.data)
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}


export function deleteFile(file) {
    return async dispatch => {
        try {
            const response = await $api.delete(`/file?id=${file._id}`)
            dispatch(deleteFileAction(file._id))
            dispatch(ShowMessage(response.data.message))
        } catch (err) {
            dispatch(ShowMessage("Ошибка сервера", "error"));
            console.log(err)
        }
    }
}


export function uploadFile(file, dirId) {
    return async dispatch => {
        try {
            const formData = new FormData()
            formData.append('file', file)
            formData.append('name', file.name)
            if (dirId) {
                formData.append('parent', dirId)
            }

            const uploadFile = {name: file.name, progress: 0, id: Date.now()}
            dispatch(showUploader())
            dispatch(addUploadFile(uploadFile))
            const response = await $api.post(`file/upload`, formData, 
            {
                onUploadProgress: progressEvent => {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength) {
                        uploadFile.progress = Math.round((progressEvent.loaded * 100) / totalLength)
                        dispatch(changeUploadFile(uploadFile))
                    }
                }
            });
            dispatch(addFile(response.data))
        } catch (err) {
            dispatch(ShowMessage("Ошибка сервера", "error"));
            console.log(err)
        }
    }
}