import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TeacherSelect from "../../components/teacherSelect";
import $api from "../../service/api-service";
import dateConverter from "../../service/dateConverter";
import { ShowMessage } from "../../store/actions/toast";
import ReviewPageApproveBtn from "./include/reviewPageApproveBtn";
import ReviewPageComment from "./include/reviewPageComment";
import { Link } from "react-router-dom";

const RewiewPage = () => {
    const [reviews, setReview] = useState([]);
    const curUser = useSelector(state=>state.currentUser.currentUser);
    const isTeamLead = curUser.role === 'teamlead';

    const userId = curUser.userID;
    const [teacherParam, setTeacherParam] = useState({
        teacherId: "", approve: "all", student: ""
    })
    const dispatch = useDispatch();

    useEffect(()=>{
        let param = userId;
        if(curUser.isAdmin && !teacherParam.teacherId) {
            param = "all"
        } else if(teacherParam.teacherId) {
            param = teacherParam.teacherId
        }

        $api.get('/review/teacher/'+param+'?approve='+teacherParam.approve)
            .then(response=>{
                setReview(response.data)
            })
    },[userId, curUser.isAdmin, teacherParam.approve, teacherParam.teacherId])

    const deleteHandler = useCallback((reviewId) => {
        $api.delete('/review/'+reviewId)
            .then(response=>{
                if( response.status === 205 ) {
                    dispatch(ShowMessage("Отзыв удален", "success"));
                    setReview(p=>p.filter( el=>el._id !== reviewId ));
                }
            }).catch(()=>{
                dispatch(ShowMessage("Ошибка сервера", "error"));
            })
    },[dispatch])

    const syncHandler = (reviewId) => {
        $api.patch('/review/sync-progress/'+reviewId)
        .then(response=>{
            if( response.status === 200 ) {
                setReview(p=>p.map(el=>{
                    if(el._id === response.data._id) {
                        return response.data
                    }
                    return el
                }))
                dispatch(ShowMessage("Синхрон", "success"));
            }
        }).catch(()=>{
            dispatch(ShowMessage("Ошибка сервера", "error"));
        })
    }

    const filtersChangeHandler= ({target}) => {
        setTeacherParam({...teacherParam,[target.name]: target.value})
    }

    return (
        <div className="container">
            <h1>Отзывы</h1>
            { (curUser.isAdmin || isTeamLead) && 
                <Link to="/review-debtors">Должники</Link>
            }
            <div className="row review-filter__wrap">
            { (curUser.isAdmin || isTeamLead) && <TeacherSelect changeHandler={filtersChangeHandler} />}
                <select name="approve" className="teachers-select" value={teacherParam.approve} onChange={filtersChangeHandler}>
                    <option value={"all"}>Все</option>
                    <option value={"false"}>Неодобренные</option>
                    <option value={"true"}>Одобренные</option>
                </select>
                <input type="text" value={teacherParam.student} name="student" onChange={filtersChangeHandler} />
            </div>
            <ul className={"review__grid " + ((curUser.isAdmin || isTeamLead) && "review__grid--admin")}>
                { (curUser.isAdmin || isTeamLead) && <li>Преп.</li>}
                <li>Дата</li>
                <li>Студент</li>
                <li>Группа</li>
                <li>Курс</li>
                <li>Комментарий</li>
                <li></li>
                <li></li>
            </ul>
            {reviews && reviews.filter(el=> new RegExp(teacherParam.student, 'gi').test(el.student?.name)).map(el=>{
                return (
                    <div className={"review__grid " + ((curUser.isAdmin || isTeamLead) && "review__grid--admin")} key={el._id}>
                        { (curUser.isAdmin || isTeamLead) && <div>{el.teacher?.userName}</div>}
                        <div>{ dateConverter(el.date) }</div>
                        <div>{el.student?.name}</div>
                        <div>{el.group?.name}</div>
                        <div>{el.courseProgress ? 
                            el.courseProgress?.courseName
                            :
                            <button onClick={()=>syncHandler(el._id)}>Синх.</button>
                        }</div>
                        <ReviewPageComment reviewId={el._id} comment={el.comment} />
                        <div>
                            {el.file && 
                                <a rel="noreferrer" target="_blank" href={el.file}>Файл</a>
                            }
                        </div>
                        <div className="review__delte-btn">
                            { (curUser.isAdmin || isTeamLead) && <ReviewPageApproveBtn reviewId={el._id} approveStatus={el.isReadenByManager} />}
                            <button onClick={()=>deleteHandler(el._id)}>Удалить</button>
                        </div>
                    </div>
                )
            }) }
        </div>
    )
}

export default RewiewPage;