import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch.js";
import UserItem from "./userItem.js";

const TeacherManagement = () => {
    const [urlParams, setUrlParams] = useState('')
    const [{response}, doFetch] = useFetch('/user/search/'+urlParams);
    const [userList, setUserList] = useState('')

    const changeHandler = ({target}) => {
        setUrlParams(target.value);
        
        if(!target.value.length < 1) {
            doFetch();
        } else {
            setUserList("");
        }
    }

    useEffect(()=>{
        if(!response){
            return
        }
        
        if(Array.isArray(response)){    
            setUserList(response.map(el=>{
                return (
                    <UserItem key={el._id} updateFunc={doFetch} userID={el._id} userName={el.userName}/>
                )
            }))}
    },[response, doFetch])

    return (
        <div>
            <input className="user-search__input" type="text" onChange={changeHandler}/>
            {userList}
        </div>
    )
}

export default TeacherManagement;