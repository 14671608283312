import React from 'react';
import AttandanceSelectItem from './attandanceSelectItem.js';

const AttandanceSelect = ({ students, lesson, lastElem=false, groupsView=true, updateFunction}) => {
    
    return(
        students.map(student => 
            <AttandanceSelectItem 
                updateFunction={updateFunction} 
                groupsView={groupsView} 
                lastElem={lastElem} 
                key={student._id} 
                student={student} 
                lesson={lesson}
                studentSum={student.balance?.sum}
            />
        )
    )
}

export default AttandanceSelect;